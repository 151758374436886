/* You can add global styles to this file, and also import other style files */

/*================================================
Default CSS
=================================================*/
:root {
    // --mainFontFamily: 'Open Sans', sans-serif;
    --optionalFontFamily: 'Adelle Sans Regular', sans-serif;
    --mainColor: #4aa2ae;
    // --gradientColor: linear-gradient(to right, #49c628, #53d23c, #5ddd4e, #66e95f, #70f570);
    --gradientColor: #4aa2ae;
    --optionalColor: #FC4A1A;
    --optionalGradientColor: linear-gradient(to right, #fc4a1a, #fd6b12, #fc8712, #faa01f, #f7b733);
    --whiteColor: #ffffff;
    --blackColor: #333333;
    --paragraphColor: #57647c;
    --eggBlueColor: #2EC7C8;
    --mediumPurpleColor: #846CD4;
    --fontSize: 14px;
    --transition: .5s;
}

body {
    padding: 0;
    margin: 0;

    font: {
        family: var(--mainFontFamily);
        size: var(--fontSize);
    }
}

a {
    display: block;
    transition: var(--transition);
    text-decoration: none;
    color: var(--blackColor);

    &:hover,
    &:focus {
        text-decoration: none;
        color: var(--mainColor);
    }
}

button {
    outline: 0 !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--blackColor);

    font: {
        family: var(--optionalFontFamily);
    }
}

.d-table {
    width: 100%;
    height: 100%;

    &-cell {
        vertical-align: middle;
    }
}

p {
    font-size: var(--fontSize);
    color: var(--paragraphColor);
    margin-bottom: 15px;
    line-height: 1.8;

    &:last-child {
        margin-bottom: 0;
    }
}

.ptb-110 {
    padding: {
        top: 110px;
        bottom: 110px;
    }
}

.pt-110 {
    padding-top: 110px;
}

.pt-160 {
    padding-top: 160px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pb-80 {
    padding-bottom: 80px;
}

.bg-f2feee {
    background-color: #f2feee;
}

.bg-f7f9fb {
    background-color: #f7f9fb;
}

img {
    max-width: 100%;
}

/*section-title*/
.section-title {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center !important;
    align-items: center !important;

    margin: {
        right: -15px;
        left: -15px;
        bottom: 60px;
    }

    .content {
        -ms-flex: 0 0 58.333333%;
        flex: 0 0 58.333333%;
        max-width: 58.333333%;

        padding: {
            left: 15px;
            right: 15px;
        }

        span {
            display: block;
            color: var(--mainColor);
            text-transform: uppercase;
            margin-bottom: 5px;

            font: {
                weight: 600;
                size: 15px;
                family: var(--optionalFontFamily);
            }
        }

        h2 {
            margin-bottom: 0;
            color: #094a70;
            font: {
                size: 40px;
                weight: 700;
            }
        }

        p {
            margin: {
                bottom: 0;
                top: 10px;
            }
        }
    }

    .image {
        -ms-flex: 0 0 41.666667%;
        flex: 0 0 41.666667%;
        max-width: 41.666667%;

        padding: {
            left: 15px;
            right: 15px;
        }
    }
}

.section-title-style-two {
    display: flex;
    flex-wrap: wrap;
    align-items: center !important;

    margin: {
        right: -15px;
        left: -15px;
        bottom: 60px;
    }

    .content {
        flex: 0 0 50%;
        max-width: 50%;

        padding: {
            left: 15px;
            right: 60px;
        }

        span {
            display: block;
            color: var(--mainColor);
            text-transform: uppercase;
            margin-bottom: 10px;

            font: {
                weight: 600;
                size: 15px;
                family: var(--optionalFontFamily);
            }
        }

        h2 {
            margin-bottom: 0;
            
            font: {
                size: 40px;
                weight: 700;
            }
        }

        p {
            margin: {
                bottom: 0;
                top: 10px;
            }
        }
    }

    .text {
        flex: 0 0 50%;
        max-width: 50%;

        padding: {
            left: 15px;
            right: 15px;
        }
    }

    &.text-center {
        display: block;
        flex-wrap: unset;
        max-width: 560px;

        margin: {
            left: auto;
            right: auto;
        }

        ;

        .content {
            flex: unset;
            max-width: 100%;

            padding: {
                left: 0;
                right: 0;
            }

            ;
        }

        .text {
            flex: unset;
            max-width: 100%;
            margin-top: 15px;

            padding: {
                left: 0;
                right: 0;
            }
        }
    }
}

/*btn-primary*/
.btn {
    border: none;
    line-height: initial;
    border-radius: 30px;
    transition: var(--transition);
    padding: 10px 32px 12px;
    position: relative;
    z-index: 1;
    opacity: 1 !important;

    font: {
        size: 16px;
        family: var(--optionalFontFamily);
        weight: 600;
    }
}

.btn-primary {
    color: var(--whiteColor);
    background-color: transparent;

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background: var(--gradientColor);
        transition: var(--transition);
        z-index: -1;
    }

    &::after {
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-radius: 30px;
        background: var(--optionalGradientColor);
        transition: var(--transition);
        z-index: -1;
        opacity: 0;
        visibility: hidden;
    }

    &:hover,
    &:focus {
        background: transparent !important;
        box-shadow: unset !important;
        color: var(--whiteColor) !important;

        &::before {
            opacity: 0;
            visibility: hidden;
        }

        &::after {
            opacity: 1;
            visibility: visible;
        }
    }
}

.btn-light {
    background-color: transparent;
    color: var(--blackColor);
    border: 1px solid #d7d7d7;
    padding: 9px 32px 11px;

    &:hover,
    &:focus {
        box-shadow: unset !important;
        background-color: var(--mainColor) !important;
        color: var(--whiteColor) !important;
        border-color: var(--mainColor) !important;
    }
}

/*form-control*/
.form-control {
    height: 45px;
    border: 1px solid #eeeeee;
    background-color: transparent;
    border-radius: 0;
    transition: var(--transition);
    color: var(--blackColor);
    line-height: initial;
    padding-left: 13px;

    font: {
        size: 14px;
    }

    &:hover,
    &:focus {
        box-shadow: unset;
    }

    &::placeholder {
        color: #999999;
    }

    &:focus {
        box-shadow: unset;
        border-color: var(--mainColor);
    }
}

/*read-more-btn*/
.read-more-btn {
    display: inline-block;

    font: {
        size: 15px;
        weight: 700;
        family: var(--optionalFontFamily);
    }

    i {
        color: var(--mainColor);
        margin-left: 2px;
        position: relative;
        top: -0.6px;

        &::before {
            font-size: 11px;
        }
    }

    &:hover {
        letter-spacing: .5px;
        color: var(--mainColor);
    }
}

/*================================================
Preloader Area CSS
=================================================*/
.preloader {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    text-align: center;
    transition: .9s;

    .sk-circle {
        transition: var(--transition);
        margin: 0 auto;
        width: 60px;
        height: 60px;
        position: absolute;
        left: 0;
        right: 0;
        top: 45%;
        transform: translateY(-45%);

        .sk-child {
            width: 100%;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;

            &::before {
                content: '';
                display: block;
                margin: 0 auto;
                width: 15%;
                height: 15%;
                background-color: var(--whiteColor);
                border-radius: 100%;
                animation: sk-circleBounceDelay 1.2s infinite ease-in-out both;
            }
        }

        .sk-circle2 {
            transform: rotate(30deg);

            &::before {
                animation-delay: -1.1s;
            }
        }

        .sk-circle3 {
            transform: rotate(60deg);

            &::before {
                animation-delay: -1s;
            }
        }

        .sk-circle4 {
            transform: rotate(90deg);

            &::before {
                animation-delay: -0.9s;
            }
        }

        .sk-circle5 {
            transform: rotate(120deg);

            &::before {
                animation-delay: -0.8s;
            }
        }

        .sk-circle6 {
            transform: rotate(150deg);

            &::before {
                animation-delay: -0.7s;
            }
        }

        .sk-circle7 {
            transform: rotate(180deg);

            &::before {
                animation-delay: -0.6s;
            }
        }

        .sk-circle8 {
            transform: rotate(210deg);

            &::before {
                animation-delay: -0.5s;
            }
        }

        .sk-circle9 {
            transform: rotate(240deg);

            &::before {
                animation-delay: -0.4s;
            }
        }

        .sk-circle10 {
            transform: rotate(270deg);

            &::before {
                animation-delay: -0.3s;
            }
        }

        .sk-circle11 {
            transform: rotate(300deg);

            &::before {
                animation-delay: -0.2s;
            }
        }

        .sk-circle12 {
            transform: rotate(330deg);

            &::before {
                animation-delay: -0.1s;
            }
        }
    }

    &::before,
    &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 60%;
        height: 100%;
        z-index: -1;
        background: var(--mainColor);
        transition: .9s;
    }

    &::after {
        left: auto;
        right: 0;
    }

    &.preloader-deactivate {
        visibility: hidden;

        &::after,
        &::before {
            width: 0;
        }

        .sk-circle {
            opacity: 0;
            visibility: hidden;
        }
    }
}

@keyframes sk-circleBounceDelay {

    0%,
    80%,
    100% {
        transform: scale(0);
    }

    40% {
        transform: scale(1);
    }
}

/*================================================
Navbar Area CSS
=================================================*/

.section {
    width: 100%;
    height: 100%;
    background: url(/assets/img/main-banner.jpg);
    background-size: cover;

    background-position: center;
}

.navbar-brand {

    padding: {
        top: 0;
        bottom: 0;
    }
}

.crimo-nav {

    background-color: transparent;

    // background-color: rgb(14,49,76);
    // padding: {
    //     top: 10px;
    //     bottom: 10px;
    //     right: 40px;
    //     left: 40px;
    // }

    .navbar {

        padding: {
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
        }

        ul {
            padding-left: 300px;
            list-style-type: none;
            margin-bottom: 0;
        }

        .navbar-nav {

            font-family: var(--optionalFontFamily);
            margin-left: 85px;

            .nav-item {

                position: relative;

                padding: {
                    top: 15px;
                    bottom: 15px;
                    left: 0;
                    right: 0;
                }

                a {
                    font: {
                        size: 17px;
                        weight: 600;
                    }

                    color: var(--blackColor);
                    text-transform: capitalize;

                    padding: {
                        left: 0;
                        right: 0;
                        top: 0;
                        bottom: 0;
                    }

                    margin: {
                        left: 13px;
                        right: 13px;
                    }

                    &:hover,
                    &:focus,
                    &.active {
                        color: var(--mainColor);
                    }

                    i {
                        font-size: 10px;
                    }

                    &::after {
                        display: none;
                    }
                }

                &:last-child {
                    a {
                        margin-right: 0;
                    }
                }

                &:first-child {
                    a {
                        margin-left: 0;
                    }
                }

                &:hover,
                &:focus,
                &.active {
                    a {
                        color: var(--mainColor);
                    }
                }

                .dropdown-menu {
                    margin-top: 15px;
                    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
                    background: var(--whiteColor);
                    border: none;
                    width: 100%;
                    z-index: 99;
                    border-radius: 0;
                    transition: all 0.3s ease-in-out;

                    padding: {
                        top: 20px;
                        left: 5px;
                        right: 5px;
                        bottom: 20px;
                    }

                    li {
                        padding: 0;

                        a {
                            text-transform: capitalize;
                            padding: 8px 15px;
                            margin: 0;
                            color: var(--blackColor);

                            font: {
                                size: 16px;
                            }

                            &:hover,
                            &:focus,
                            &.active {
                                color: var(--mainColor);
                            }
                        }

                        .dropdown-menu {
                            left: 220px;
                            top: 0;
                            opacity: 0;
                            visibility: hidden;

                            li {
                                a {
                                    color: var(--blackColor);

                                    &:hover,
                                    &:focus,
                                    &.active {
                                        color: var(--mainColor);
                                    }
                                }

                                .dropdown-menu {
                                    left: 220px;
                                    top: 0;
                                    opacity: 0;
                                    visibility: hidden;

                                    li {
                                        a {
                                            color: var(--blackColor);
                                            text-transform: capitalize;

                                            &:hover,
                                            &:focus,
                                            &.active {
                                                color: var(--mainColor);
                                            }
                                        }

                                        .dropdown-menu {
                                            left: -250px;
                                            top: 0;
                                            opacity: 0;
                                            visibility: hidden;

                                            li {
                                                a {
                                                    color: var(--blackColor);
                                                    text-transform: capitalize;

                                                    &:hover,
                                                    &:focus,
                                                    &.active {
                                                        color: var(--mainColor);
                                                    }
                                                }

                                                .dropdown-menu {
                                                    left: -250px;
                                                    top: 0;
                                                    opacity: 0;
                                                    visibility: hidden;

                                                    li {
                                                        a {
                                                            color: var(--blackColor);
                                                            text-transform: capitalize;

                                                            &:hover,
                                                            &:focus,
                                                            &.active {
                                                                color: var(--mainColor);
                                                            }
                                                        }

                                                        .dropdown-menu {
                                                            left: -250px;
                                                            top: 0;
                                                            opacity: 0;
                                                            visibility: hidden;

                                                            li {
                                                                a {
                                                                    color: var(--blackColor);
                                                                    text-transform: capitalize;

                                                                    &:hover,
                                                                    &:focus,
                                                                    &.active {
                                                                        color: var(--mainColor);
                                                                    }
                                                                }

                                                                .dropdown-menu {
                                                                    left: -250px;
                                                                    top: 0;
                                                                    opacity: 0;
                                                                    visibility: hidden;

                                                                    li {
                                                                        a {
                                                                            color: var(--blackColor);
                                                                            text-transform: capitalize;

                                                                            &:hover,
                                                                            &:focus,
                                                                            &.active {
                                                                                color: var(--mainColor);
                                                                            }
                                                                        }
                                                                    }
                                                                }

                                                                &.active {
                                                                    a {
                                                                        color: var(--mainColor);
                                                                    }
                                                                }

                                                                &:hover {
                                                                    .dropdown-menu {
                                                                        opacity: 1;
                                                                        visibility: visible;
                                                                        top: -15px;
                                                                    }
                                                                }
                                                            }
                                                        }

                                                        &.active {
                                                            a {
                                                                color: var(--mainColor);
                                                            }
                                                        }

                                                        &:hover {
                                                            .dropdown-menu {
                                                                opacity: 1;
                                                                visibility: visible;
                                                                top: -15px;
                                                            }
                                                        }
                                                    }
                                                }

                                                &.active {
                                                    a {
                                                        color: var(--mainColor);
                                                    }
                                                }

                                                &:hover {
                                                    .dropdown-menu {
                                                        opacity: 1;
                                                        visibility: visible;
                                                        top: -15px;
                                                    }
                                                }
                                            }
                                        }

                                        &.active {
                                            a {
                                                color: var(--mainColor);
                                            }
                                        }

                                        &:hover {
                                            .dropdown-menu {
                                                opacity: 1;
                                                visibility: visible;
                                                top: -15px;
                                            }
                                        }
                                    }
                                }

                                &.active {
                                    a {
                                        color: var(--mainColor);
                                    }
                                }

                                &:hover {
                                    .dropdown-menu {
                                        opacity: 1;
                                        visibility: visible;
                                        top: -15px;
                                    }
                                }
                            }
                        }

                        &.active {
                            a {
                                color: var(--mainColor);
                            }
                        }

                        &:hover {
                            .dropdown-menu {
                                opacity: 1;
                                visibility: visible;
                                top: -15px;
                            }
                        }
                    }
                }
            }
        }

        .others-options {
            margin-left: 20px;

            .cart-btn {
                display: inline-block;
                position: relative;
                top: 3px;
            }

            .btn {
                margin-left: 15px;
            }

            .option-item {
                color: var(--blackColor);
                display: inline-block;
                position: relative;
                line-height: initial;
                margin-left: 15px;
                top: 4px;

                .search-btn {
                    cursor: pointer;
                    transition: var(--transition);

                    &::before {
                        font-size: 17px;
                    }

                    &:hover {
                        color: var(--mainColor);
                    }
                }

                .close-btn {
                    cursor: pointer;
                    display: none;
                    font-size: 17px;
                    transition: var(--transition);
                    width: 17px;

                    &.active {
                        display: block;
                    }

                    &:hover {
                        color: var(--mainColor);
                    }
                }

                a {
                    color: var(--blackColor);

                    i {
                        &::before {
                            font-size: 17px;
                        }
                    }

                    span {
                        display: inline-block;
                        width: 15px;
                        height: 15px;
                        background: var(--optionalGradientColor);
                        border-radius: 50%;
                        text-align: center;
                        color: var(--whiteColor);
                        line-height: 15px;

                        font: {
                            weight: 600;
                            size: 10px;
                        }

                        position: relative;
                        top: -12px;
                        left: -12px;
                    }
                }
            }
        }
    }

    &.navbar-style-two {
        .navbar {
            .navbar-nav {
                margin-left: auto;
            }
        }
    }
}

.navbar-area {
    // background-color: currentColor;
    background-color: rgb(14, 49, 76);
    position: absolute;
    width: 100%;
    height: auto;
    left: 0;
    top: 0;
    z-index: 1000;
    transition: var(--transition);

    &.is-sticky {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 999;
        box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
        // background-color: transparent;
        background-color: rgb(14, 49, 76);
        animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
        transition: var(--transition);

        .crimo-nav {
            background-color: rgb(14, 49, 76);

            padding: {
                top: 10px;
                bottom: 10px;
            }

            .navbar {
                .navbar-nav {
                    margin-left: auto;
                }
            }
        }
    }
}

.search-overlay {
    display: none;

    &.search-popup {
        position: absolute;
        top: 100%;
        width: 300px;
        right: 0;
        background: var(--whiteColor);
        z-index: 2;
        padding: 20px;
        box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
        margin-top: 20px;

        .search-form {
            position: relative;

            .search-input {
                display: block;
                width: 100%;
                height: 40px;
                line-height: initial;
                // border: 1px solid #eeeeee;
                padding-left: 10px;
                color: var(--blackColor);
                outline: 0;
                transition: var(--transition);

                &:focus {
                    border-color: var(--mainColor);
                }
            }

            .search-button {
                position: absolute;
                right: 0;
                top: 0;
                height: 40px;
                background: transparent;
                border: none;
                width: 40px;
                outline: 0;
                color: var(--paragraphColor);
                transition: var(--transition);

                &:hover,
                &:focus {
                    color: var(--mainColor);
                }
            }
        }
    }
}

.navbar-light {
    .navbar-toggler {
        color: var(--blackColor);
        border-color: var(--blackColor);
        border-radius: 0;

        .navbar-toggler-icon {
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
        }
    }
}

// Mobile and iPad Navbar
@media only screen and (max-width: 767px) {

    .navbar-area {
        //    background-color: transparent;
        // background-color: rgb(14,49,76);
        // position: relative;

        // border-bottom: 1px solid rgba(0, 0, 0, 0.03);
        padding: {
            top: 15px;
            bottom: 15px;
        }

        &.is-sticky {
            border-bottom: none;
            box-shadow: 0 7px 13px 0 rgba(0, 0, 0, .1);
        }
    }

    .crimo-nav {
        // padding: {
        //     top: 0;
        //     bottom: 0;
        //     right: 0;
        //     left: 0;
        // }



        .navbar {
            .navbar-nav {
                margin-left: 0;

                .nav-item {
                    padding-top: 10px;
                    padding-bottom: 10px;

                    a {
                        margin-left: 0;
                        margin-right: 0;
                    }
                }
            }
        }

        #navbarSupportedContent {
            // border: 1px solid #eeeeee;
            padding: 5px 15px;
            margin-top: 15px;
        }
    }

    .others-options {
        display: none;
    }

}

@media only screen and (min-width: 768px) {

    .crimo-nav {
        .navbar {
            .navbar-nav {
                font-family: var(--optionalFontFamily);
                margin-left: 85px;

                .nav-item {
                    .dropdown-menu {
                        position: absolute;
                        margin-top: 0;
                        top: 80px;
                        left: 0;
                        width: 280px;
                        display: block;
                        opacity: 0;
                        visibility: hidden;
                    }

                    &:hover {
                        .dropdown-menu {
                            opacity: 1;
                            visibility: visible;
                            top: 100%;
                        }
                    }
                }
            }
        }
    }

}

/*================================================
Main Banner Area CSS
=================================================*/
.main-banner {
    position: relative;
    z-index: 1;
}

.main-banner-content {
    height: 880px;
    position: relative;
    z-index: 1;

    padding: {
        left: 75px;
        right: 30px;
    }

    h1 {
        margin: {
            bottom: 0;
            top: -60px;
        }

        font: {
            size: 80px;
            weight: 700;
        }

        span {
            color: var(--mainColor);
        }
    }

    p {
        font-size: 14.5px;
        max-width: 620px;

        margin: {
            top: 25px;
            bottom: 0;
        }
    }

    .btn-box {
        margin-top: 30px;

        .btn-light {
            margin-left: 15px;
        }
    }
}

.main-banner-image {
    height: 100%;
    width: 100%;
    position: relative;
    text-align: center;

    background: {
        image: url(assets/img/banner-img1.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }

    img {
        display: none;
    }

    .video-btn {
        position: absolute;
        right: 0;
        left: 0;
        display: inline-block;
        margin: 0 auto;
        top: 50%;
        transform: translateY(-50%);
        width: 90px;
        height: 90px;
        line-height: 90px;
        background-color: var(--mainColor);
        border-radius: 50%;
        color: var(--whiteColor);
        z-index: 1;

        i {
            &::before {
                font-size: 38px;
            }
        }

        &::after,
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid var(--mainColor);
        }

        &::before {
            animation: ripple 2s linear infinite
        }

        &::after {
            animation: ripple 2s linear 1s infinite
        }

        &:hover,
        &:focus {
            background-color: var(--optionalColor);
            color: var(--whiteColor);
        }
    }
}

.banner-inner-content {
    overflow: hidden;
    position: absolute;
    left: 0;
    bottom: 0;
    background: var(--mainColor);
    z-index: 1;
    width: 100%;
    max-width: 960px;
    border-radius: 0 30px 0 0;

    padding: {
        top: 40px;
        bottom: 40px;
        left: 150px;
        right: 30px;
    }

    .single-inner-box {
        position: relative;
        padding-left: 35px;

        .icon {
            color: var(--whiteColor);
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);

            i {
                &::before {
                    font-size: 23px;
                }
            }
        }

        h3 {
            margin-bottom: 0;

            font: {
                size: 23px;
                weight: 700;
            }

            a {
                color: var(--whiteColor);
            }

            i {
                margin-left: 3px;

                &::before {
                    font-size: 10px;
                }
            }
        }
    }

    .map {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }
}

.shape1 {
    position: absolute;
    left: 0;
    top: 27%;
    z-index: -1;
    animation: movebounce 5s linear infinite;
}

.shape2 {
    position: absolute;
    top: 50%;
    z-index: -1;
    left: 0;
    display: none;
}

.shape3 {
    position: absolute;
    z-index: -1;
    bottom: 205px;
    left: 85px;
    animation: movescale 3s linear infinite;
}

.shape4 {
    position: absolute;
    z-index: -1;
    left: 5px;
    bottom: 98px;
    animation: movebounce 5s linear infinite;
}

.shape5 {
    position: absolute;
    z-index: -1;
    bottom: 12%;
    left: 20%;
    animation-name: rotateme;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.shape6 {
    position: absolute;
    z-index: -1;
    left: 34%;
    bottom: 26%;
    animation: rotate3d 4s linear infinite;
}

.shape7 {
    position: absolute;
    z-index: -1;
    left: 52%;
    bottom: 14%;
    animation: rotate3d 4s linear infinite;
    display: none;
}

.shape8 {
    position: absolute;
    z-index: -1;
    left: 48%;
    bottom: 17%;
    animation: movescale 3s linear infinite;
}

.shape9 {
    position: absolute;
    z-index: 1;
    left: 53.7%;
    top: 35%;
    animation: movebounce 5s linear infinite;
}

.shape10 {
    position: absolute;
    z-index: -1;
    top: 15%;
    left: 50%;
    animation: movescale 3s linear infinite;
}

.shape11 {
    position: absolute;
    z-index: -1;
    top: 14%;
    left: 30%;
    animation: moveleftbounce 5s linear infinite;
}

.shape12 {
    position: absolute;
    z-index: -1;
    top: 13%;
    left: 14%;
    animation-name: rotateme;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.shape13 {
    position: absolute;
    z-index: -1;
    bottom: 13%;
    left: 14%;
    animation: animationFramesOne 20s infinite linear;
}

.shape14 {
    position: absolute;
    z-index: -1;
    top: 13%;
    right: 14%;
    animation: animationFramesOne 20s infinite linear;
}

.shape15 {
    position: absolute;
    z-index: -1;
    top: 13%;
    left: 14%;
    animation: animationFramesOne 20s infinite linear;
}

@keyframes rotateme {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes movebounce {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(20px);
    }

    100% {
        transform: translateY(0px);
    }
}

@keyframes moveleftbounce {
    0% {
        transform: translateX(0px);
    }

    50% {
        transform: translateX(20px);
    }

    100% {
        transform: translateX(0px);
    }
}

@keyframes movescale {
    0% {
        transform: scale(.6);
    }

    50% {
        transform: scale(.8);
    }

    100% {
        transform: scale(.6);
    }
}

@keyframes rotate3d {
    0% {
        -webkit-transform: rotateY(0deg);
        transform: rotateY(0deg);
    }

    100% {
        -webkit-transform: rotateY(360deg);
        transform: rotateY(360deg);
    }
}

@keyframes animationFramesOne {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }

    20% {
        transform: translate(73px, -1px) rotate(36deg);
    }

    40% {
        transform: translate(141px, 72px) rotate(72deg);
    }

    60% {
        transform: translate(83px, 122px) rotate(108deg);
    }

    80% {
        transform: translate(-40px, 72px) rotate(144deg);
    }

    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

@keyframes ripple {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }

    75% {
        -webkit-transform: scale(1.75);
        transform: scale(1.75);
        opacity: 1
    }

    100% {
        -webkit-transform: scale(2);
        transform: scale(2);
        opacity: 0
    }
}

/*================================================
Services Area CSS
=================================================*/
.services-area {
    padding-bottom: 80px;
    position: relative;
    z-index: 1;

    .pagination-area {
        margin-bottom: 30px;
    }
}

.single-services-box {
    text-align: center;
    margin-bottom: 30px;
    background-color: #f8fbf8;
    transition: var(--transition);
    border-bottom: 3px solid var(--mainColor);
    border-radius: 5px;
    padding: 25px;

    .icon {
        margin-bottom: 18px;
        position: relative;
        display: inline-block;
        z-index: 1;
        width: 80px;
        height: 80px;
        line-height: 80px;
        border: 1px solid #dcffd0;
        border-radius: 50%;
        color: var(--mainColor);
        transition: var(--transition);

        i {
            &.flaticon-plugin {
                top: -2px;
                left: -2px;
                position: relative;
            }

            &::before {
                font-size: 32px;
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background-color: #daffce;
            z-index: -1;
            border-radius: 50%;
            margin: 5px;
            transition: var(--transition);
        }
    }

    h3 {
        margin-bottom: 0;

        font: {
            size: 23px;
            weight: 700;
        }
    }

    p {
        margin: {
            top: 13px;
            bottom: 0;
        }
    }

    .read-more-btn {
        margin-top: 15px;
    }

    &:hover {
        background-color: var(--whiteColor);
        box-shadow: 0 0 40px 3px rgba(0, 0, 0, 0.05);
        transform: translateY(-3px);

        .icon {
            border-color: var(--mainColor);
            color: var(--whiteColor);

            &::before {
                background: var(--mainColor);
            }
        }
    }
}

/*================================================
Services Details Area CSS
=================================================*/
.services-details-overview {
    -ms-flex-align: center !important;
    align-items: center !important;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;

    margin: {
        right: -15px;
        left: -15px;
        top: 50px;
    }

    &:first-child {
        margin-top: 0;
    }

    .services-details-desc {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;

        padding: {
            right: 15px;
            left: 15px;
        }

        h3 {
            margin-bottom: 15px;

            font: {
                size: 23px;
                weight: 700;
            }
        }

        .services-details-accordion {
            .accordion {
                list-style-type: none;
                padding-left: 0;

                margin: {
                    bottom: 0;
                    top: 25px;
                }

                .accordion-item {
                    display: block;
                    box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
                    background: var(--whiteColor);
                    margin-bottom: 10px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                .accordion-title {
                    padding: 12px 20px 12px 51px;
                    color: var(--blackColor);
                    position: relative;
                    border-bottom: 1px solid transparent;
                    margin-bottom: -1px;

                    font: {
                        size: 15px;
                        weight: 700;
                        family: var(--optionalFontFamily);
                    }

                    i {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 40px;
                        text-align: center;
                        height: 100%;
                        background: var(--mainColor);
                        color: var(--whiteColor);

                        &::before {
                            position: absolute;
                            left: 0;
                            top: 50%;
                            right: 0;
                            transform: translateY(-50%);
                            font-size: 13px;
                        }
                    }

                    &.active {
                        border-bottom-color: #eeeeee;

                        i {
                            &::before {
                                content: "\f068";
                            }
                        }
                    }
                }

                .accordion-content {
                    display: none;
                    position: relative;
                    padding: 15px 15px;

                    &.show {
                        display: block;
                    }
                }
            }
        }
    }

    .services-details-image {
        -ms-flex: 0 0 50%;
        flex: 0 0 50%;
        max-width: 50%;

        padding: {
            right: 15px;
            left: 15px;
        }
    }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
    .row {
        position: relative;
        z-index: 1;

        padding: {
            top: 60px;
            bottom: 60px;
        }

        &::before {
            z-index: -1;
            content: '';
            position: absolute;
            right: 15px;
            top: 0;
            height: 100%;
            width: 60%;
            opacity: .75;

            background: {
                // image: url(assets/img/shape1.png);
                position: center center;
                size: cover;
                repeat: no-repeat;
            }
        }
    }
}

.about-content {
    padding: {
        left: 10px;
        right: 25px;
    }

    span {
        display: block;
        color: var(--mainColor);
        text-transform: uppercase;
        margin-bottom: 1px;

        font: {
            weight: 600;
            size: 15px;
            family: var(--optionalFontFamily);
        }
    }

    h2 {
        margin-bottom: 0;

        font: {
            size: 40px;
            weight: 700;
        }
    }

    p {
        margin: {
            bottom: 0;
            top: 13px;
        }
    }

    .btn {
        margin-top: 20px;
    }
}

.about-inner-area {
    margin-top: 60px;

    .row {
        padding: 0;

        &::before {
            display: none;
        }
    }
}

.about-text {
    h3 {
        margin-bottom: 0;

        font: {
            size: 23px;
            weight: 700;
        }
    }

    p {
        margin: {
            top: 13px;
            bottom: 0;
        }
    }
}

/*================================================
Why Choose Us Area CSS
=================================================*/
.why-choose-area {
    padding-bottom: 80px;
    position: relative;
    z-index: 1;
}

.single-choose-box {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;
    transition: var(--transition);
    border-radius: 5px;

    img {
        transition: var(--transition);
        border-radius: 5px;
    }

    .title {
        position: absolute;
        left: 32px;
        bottom: 25px;
        z-index: 1;
        transition: var(--transition);
        max-width: 230px;

        h3 {
            background-color: var(--mainColor);
            color: var(--whiteColor);
            display: inline;
            white-space: pre-wrap;
            box-shadow: -7px 0 0 var(--mainColor);

            font: {
                size: 23px;
                weight: 700;
            }

            padding: {
                left: 0;
                right: 7px;
                top: 0;
                bottom: 3px;
            }
        }
    }

    .content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        background: var(--mainColor);
        border-radius: 5px;
        padding: 25px;
        transition: var(--transition);
        transform: scale(1.1);
        opacity: 0;
        visibility: hidden;

        margin: {
            top: 25px;
            bottom: 25px;
            left: 25px;
            right: 25px;
        }

        .inner-content {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 25px;
            right: 25px;
            margin: 0 auto;

            h3 {
                color: var(--whiteColor);
                line-height: 1.3;

                margin: {
                    bottom: 0;
                    top: -6px;
                }

                font: {
                    size: 23px;
                    weight: 700;
                }

                a {
                    color: var(--whiteColor);
                }
            }

            p {
                color: var(--whiteColor);

                margin: {
                    bottom: 15px;
                    top: 13px;
                }
            }

            .read-more-btn {
                color: var(--whiteColor);

                i {
                    color: var(--whiteColor);
                }
            }
        }
    }

    &:hover {
        img {
            transform: scale(1.3);
        }

        .content {
            opacity: 1;
            visibility: visible;
            transform: scale(1);
        }

        .title {
            opacity: 0;
            visibility: hidden;
        }
    }
}

/*================================================
Funfacts Area CSS
=================================================*/
.single-funfacts {
    position: relative;
    padding-left: 150px;

    .circlechart {
        width: 130px;
        height: 130px;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);

        .circle-chart {
            width: 130px;
            height: 130px;

            .circle-chart__background {
                stroke: #a9b4c5;
                stroke-width: .5;
            }

            .circle-chart__circle {
                stroke: var(--mainColor) !important;
                stroke-width: 1.5;
            }

            .circle-chart__info {
                font: {
                    family: var(--optionalFontFamily);
                    weight: 600;
                }

                .circle-chart__percent {
                    position: relative;
                }
            }
        }
    }

    span {
        display: block;
        color: var(--paragraphColor);
        text-transform: uppercase;
        margin-bottom: 2px;

        font: {
            family: var(--optionalFontFamily);
            weight: 600;
        }
    }

    h3 {
        margin-bottom: 0;

        font: {
            size: 34px;
            weight: 700;
        }
    }
}

/*================================================
CTA Area CSS
=================================================*/
.cta-area {
    padding: {
        top: 180px;
        bottom: 180px;
    }

    position: relative;
    z-index: 1;

    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
        image: url(assets/img/cta-bg.jpg);
    }

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: #382e2d;
        opacity: .40;
    }
}

.cta-content {
    text-align: center;
    max-width: 745px;

    margin: {
        left: auto;
        right: auto;
    }

    span {
        display: block;
        color: var(--whiteColor);
        margin-bottom: 5px;

        font: {
            size: 24px;
            family: var(--optionalFontFamily);
        }
    }

    h2 {
        margin-bottom: 0;
        color: var(--whiteColor);
        line-height: 1;

        font: {
            size: 80px;
            weight: 700;
        }
    }

    .btn {
        margin-top: 35px;
    }
}

/*================================================
Projects Area CSS
=================================================*/
.projects-area {
    padding-bottom: 80px;
    overflow: hidden;
    position: relative;
    z-index: 1;

    .pagination-area {
        margin: {
            bottom: 30px;
            top: 30px;
        }
    }
}

.single-projects-box {
    margin-bottom: 30px;
    transition: var(--transition);
    background-color: var(--whiteColor);

    a {
        img {
            transition: var(--transition);
        }
    }

    .projects-content {
        transition: var(--transition);
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
        padding: 30px;

        span {
            display: block;
            color: var(--paragraphColor);
            margin-bottom: 7px;

            font: {
                size: 13px;
                weight: 600;
            }
        }

        h3 {
            margin-bottom: 0;

            font: {
                size: 23px;
                weight: 700;
            }
        }

        p {
            margin: {
                top: 11px;
                bottom: 0;
            }
        }

        .read-more-btn {
            margin-top: 15px;
        }
    }

    &:hover {
        a {
            img {
                opacity: .75;
            }
        }
    }
}

.projects-slides {
    left: calc((100% - 935px) / 2);
    position: relative;

    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            position: absolute;
            left: -178px;
            top: 50%;
            transform: translateY(-50%);

            [class*=owl-] {
                display: block;
                margin: 0;
                text-align: center;
                height: 50px;
                line-height: 50px;
                background: var(--optionalGradientColor);
                color: var(--whiteColor);
                border-radius: 30px;
                transition: var(--transition);
                position: relative;
                z-index: 1;

                font: {
                    weight: 600;
                    size: 17px;
                    family: var(--optionalFontFamily);
                }

                padding: {
                    left: 88px !important;
                    right: 50px !important;
                }

                i {
                    &::before {
                        font-size: 13px;
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    background: var(--blackColor);
                    transition: var(--transition);
                    border-radius: 30px;
                    border: 0;
                }

                &:after {
                    content: 'Next';
                    position: absolute;
                    color: var(--whiteColor);
                    top: 50%;
                    transform: translateY(-50%);
                    left: 50px;
                }

                &.owl-prev {
                    margin-bottom: 15px;
                    color: var(--whiteColor);

                    padding: {
                        left: 50px !important;
                        right: 88px !important;
                    }

                    &::after {
                        content: 'Prev';
                        left: auto;
                        right: 50px;
                    }

                    &::before {
                        opacity: 1;
                        visibility: visible;
                        background: var(--gradientColor);
                    }
                }

                &.owl-next {
                    margin-top: 15px;
                }

                &:hover {
                    color: var(--whiteColor);

                    &::before {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
    }
}

/*================================================
Projects Details Area CSS
=================================================*/
.projects-details {
    padding: 40px;
    box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2);
}

.projects-details-desc {
    margin-top: 25px;

    h3 {
        margin-bottom: 13px;

        font: {
            size: 23px;
            weight: 700;
        }
    }

    .project-details-info {
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        margin: {
            right: -15px;
            left: -15px;
            top: 30px;
        }

        .single-info-box {
            -ms-flex: 0 0 20%;
            flex: 0 0 20%;
            max-width: 20%;

            padding: {
                left: 15px;
                right: 15px;
            }

            h4 {
                margin-bottom: 12px;

                font: {
                    size: 18px;
                    weight: 700;
                }
            }

            span {
                display: block;
                color: var(--paragraphColor);
            }

            .social {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                    margin-right: 10px;

                    a {
                        color: var(--paragraphColor);

                        &:hover {
                            color: var(--mainColor);
                            transform: translateY(-5px);
                        }
                    }
                }
            }
        }
    }
}

.projects-image-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
        }

        .owl-nav {
            [class*=owl-] {
                position: absolute;
                left: 50px;
                top: 50%;
                transform: translateY(-50%);
                margin: 0;
                width: 40px;
                height: 40px;
                line-height: 40px;
                color: var(--whiteColor);
                background-color: var(--mainColor);
                border-radius: 5px;
                font-size: 15px;
                transition: var(--transition);
                opacity: 0;
                visibility: hidden;

                &.owl-next {
                    left: auto;
                    right: 50px;
                }

                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--optionalColor);
                }
            }
        }

        &:hover {
            .owl-nav {
                [class*=owl-] {
                    opacity: 1;
                    visibility: visible;
                    left: 25px;

                    &.owl-next {
                        left: auto;
                        right: 25px;
                    }
                }
            }
        }
    }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    padding: {
        top: 70px;
        bottom: 30px;
    }
}

.partner-list {
    display: -ms-flexbox;
    display: -webkit-box;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center !important;
    -webkit-box-align: center !important;
    align-items: center !important;

    margin: {
        right: -15px;
        left: -15px;
    }

    .partner-item {
        -ms-flex: 0 0 16.6666666667%;
        -webkit-box-flex: 0;
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%;
        text-align: center;
        margin-bottom: 40px;
        position: relative;
        overflow: hidden;

        padding: {
            left: 15px;
            right: 15px;
        }

        a {
            &:nth-child(2) {
                position: absolute;
                left: 0;
                top: 20px;
                right: 0;
                margin: 0 auto;
                opacity: 0;
                visibility: hidden;
                transition: var(--transition);
            }
        }

        &:hover {
            a {
                top: 0;
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

/*================================================
Team Area CSS
=================================================*/
.team-area {
    padding-bottom: 80px;
    position: relative;
    z-index: 1;
}

.single-team-box {
    margin-bottom: 30px;
    text-align: center;
    border-radius: 5px 5px 0 0;

    .team-image {
        position: relative;
        overflow: hidden;

        img {
            border-radius: 5px;
            transition: var(--transition);
        }

        .social {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);

            margin: {
                left: auto;
                right: auto;
            }

            li {
                display: inline-block;
                transition: var(--transition);

                margin: {
                    left: 3px;
                    right: 3px;
                }

                a {
                    width: 35px;
                    height: 35px;
                    background-color: #343434;
                    line-height: 35px;
                    border-radius: 50%;
                    color: var(--whiteColor);

                    i {
                        &::before {
                            font-size: 13px;
                        }
                    }

                    &:hover {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }

    .team-content {
        margin-top: 25px;

        h3 {
            margin-bottom: 0;
            color: var(--mainColor);

            font: {
                size: 23px;
                weight: 700;
            }
        }

        span {
            display: block;
            margin-top: 7px;
            color: var(--paragraphColor);

            font: {
                family: var(--optionalFontFamily);
                size: 15px;
            }
        }
    }

    &:hover {
        .team-image {
            .social {
                opacity: 1;
                visibility: visible;
                bottom: 15px;
            }

            img {
                transform: scale(1.1);
            }
        }
    }
}

/*================================================
Testimonials Area CSS
=================================================*/
.testimonials-area {
    position: relative;
    z-index: 1;

    padding: {
        top: 110px;
    }
}

.testimonials-inner-area {
    position: relative;

    padding: {
        bottom: 110px;
        top: 110px;
    }

    background: {
        image: url(assets/img/testimonials-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }

    .video-btn {
        position: absolute;
        text-align: center;
        right: 20%;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
        width: 90px;
        height: 90px;
        line-height: 90px;
        background-color: var(--mainColor);
        border-radius: 50%;
        color: var(--whiteColor);
        z-index: 1;

        i {
            &::before {
                font-size: 38px;
            }
        }

        &::after,
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid var(--mainColor);
        }

        &::before {
            animation: ripple 2s linear infinite
        }

        &::after {
            animation: ripple 2s linear 1s infinite
        }

        &:hover,
        &:focus {
            background-color: var(--optionalColor);
            color: var(--whiteColor);
        }
    }
}

.testimonials-inner-content {
    background-color: var(--mainColor);
    position: relative;
    z-index: 1;
    overflow: hidden;
    max-width: 875px;
    border-radius: 0 0 50px 0;
    margin-top: -220px;

    padding: {
        top: 110px;
        bottom: 110px;
        right: 100px;
    }

    .circle-map {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        z-index: -1;
    }
}

.single-testimonials-item {
    position: relative;
    z-index: 1;
    padding-top: 50px;
    max-width: 580px;
    margin-left: auto;

    &::before {
        content: "\f121";
        position: absolute;
        left: 0;
        top: -10px;
        color: #ebebeb;

        font: {
            family: Flaticon;
            size: 40px;
            style: normal;
        }
    }

    p {
        margin-bottom: 0;
        color: var(--whiteColor);

        font: {
            size: 19px;
            style: italic;
        }
    }

    .client-info {
        position: relative;
        padding-left: 58px;
        margin-top: 25px;

        img {
            width: 45px !important;
            height: 45px !important;
            display: inline-block !important;
            border-radius: 50%;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }

        h3 {
            margin-bottom: 0;
            color: var(--whiteColor);

            font: {
                size: 18px;
                weight: 600;
            }
        }

        span {
            display: block;
            font-weight: 300;
            margin-top: 2px;
            color: var(--whiteColor);
        }
    }
}

.testimonials-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            position: absolute;
            right: 0;
            bottom: 0;

            [class*=owl-] {
                color: var(--whiteColor);
                font-size: 16px;
                margin: 0 6px;
                background: transparent;
                transition: var(--transition);

                i {
                    &::before {
                        font-size: 16px;
                    }
                }

                &:hover {
                    color: var(--blackColor);
                }
            }
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.blog-area {
    padding-bottom: 80px;
    position: relative;
    z-index: 1;

    &.extra-pb {
        padding-bottom: 155px;
    }

    .pagination-area {
        margin-bottom: 30px;
    }
}

.single-blog-post {
    margin-bottom: 30px;
    transition: var(--transition);
    border-radius: 5px;

    .entry-thumbnail {
        a {
            img {
                transition: var(--transition);
                border-radius: 5px;
            }
        }
    }

    .entry-post-content {
        margin-top: 20px;

        .entry-meta {
            margin-bottom: 6px;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                    position: relative;
                    margin-right: 12px;
                    color: var(--paragraphColor);

                    font: {
                        family: var(--optionalFontFamily);
                        size: 15px;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        right: -7px;
                        top: 50%;
                        transform: rotate(14deg) translateY(-50%);
                        background: var(--paragraphColor);
                        width: 1px;
                        height: 13px;
                    }

                    a {
                        display: inline-block;
                        color: var(--paragraphColor);

                        &:hover {
                            color: var(--mainColor);
                        }
                    }

                    &:last-child {
                        margin-right: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        h3 {
            margin-bottom: 0;
            line-height: 1.4;

            font: {
                size: 23px;
                weight: 700;
            }
        }

        p {
            margin: {
                top: 8px;
                bottom: 0;
            }
        }

        .read-more-btn {
            margin-top: 15px;
        }
    }

    &.sticky {
        position: relative;

        .entry-post-content {
            margin-top: 0;
            padding: 25px;
            background-color: var(--mainColor);
            border-radius: 5px;

            .entry-meta {
                margin-bottom: 8px;

                ul {
                    li {
                        color: var(--whiteColor);

                        &::before {
                            background: var(--whiteColor);
                        }

                        a {
                            color: var(--whiteColor);
                        }
                    }
                }
            }

            h3 {
                a {
                    position: relative;
                    display: inline;
                    transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
                    padding: 0.1% 0px;
                    color: var(--whiteColor);

                    background: {
                        image: -webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor));
                        image: -webkit-linear-gradient(left, currentColor 0%, currentColor 100%);
                        image: -o-linear-gradient(left, currentColor 0%, currentColor 100%);
                        image: linear-gradient(to right, currentColor 0%, currentColor 100%);
                        size: 0px 2px;
                        position: 0px 95%;
                        repeat: no-repeat;
                    }

                    &:hover {
                        background-size: 100% 2px;
                    }
                }
            }
        }

        &::before {
            content: '';
            position: absolute;
            right: 15px;
            top: 15px;
            content: "\f122";
            color: var(--whiteColor);

            font: {
                family: Flaticon;
                size: 30px;
                style: normal;
            }
        }
    }

    &.link {
        position: relative;

        .entry-post-content {
            margin-top: 0;
            padding: 25px;
            background-color: #a26565;
            border-radius: 5px;

            .entry-meta {
                margin-bottom: 8px;

                ul {
                    li {
                        color: var(--whiteColor);

                        &::before {
                            background: var(--whiteColor);
                        }

                        a {
                            color: var(--whiteColor);
                        }
                    }
                }
            }

            h3 {
                a {
                    position: relative;
                    display: inline;
                    transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
                    padding: 0.1% 0px;
                    color: var(--whiteColor);

                    background: {
                        image: -webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor));
                        image: -webkit-linear-gradient(left, currentColor 0%, currentColor 100%);
                        image: -o-linear-gradient(left, currentColor 0%, currentColor 100%);
                        image: linear-gradient(to right, currentColor 0%, currentColor 100%);
                        size: 0px 2px;
                        position: 0px 95%;
                        repeat: no-repeat;
                    }

                    &:hover {
                        background-size: 100% 2px;
                    }
                }
            }
        }

        &::before {
            content: '';
            position: absolute;
            right: 20px;
            top: 10px;
            content: "\f123";
            color: var(--whiteColor);

            font: {
                family: Flaticon;
                size: 30px;
                style: normal;
            }
        }
    }

    &.quote {
        position: relative;

        .entry-post-content {
            margin-top: 0;
            padding: 25px;
            background-color: #57647c;
            border-radius: 5px;

            .entry-meta {
                margin-bottom: 8px;

                ul {
                    li {
                        color: var(--whiteColor);

                        &::before {
                            background: var(--whiteColor);
                        }

                        a {
                            color: var(--whiteColor);
                        }
                    }
                }
            }

            h3 {
                a {
                    position: relative;
                    display: inline;
                    transition: background-size 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86) 0s;
                    padding: 0.1% 0px;
                    color: var(--whiteColor);

                    background: {
                        image: -webkit-gradient(linear, left top, right top, from(currentColor), to(currentColor));
                        image: -webkit-linear-gradient(left, currentColor 0%, currentColor 100%);
                        image: -o-linear-gradient(left, currentColor 0%, currentColor 100%);
                        image: linear-gradient(to right, currentColor 0%, currentColor 100%);
                        size: 0px 2px;
                        position: 0px 95%;
                        repeat: no-repeat;
                    }

                    &:hover {
                        background-size: 100% 2px;
                    }
                }
            }
        }

        &::before {
            content: '';
            position: absolute;
            right: 20px;
            top: 20px;
            content: "\f121";
            color: var(--whiteColor);

            font: {
                family: Flaticon;
                size: 30px;
                style: normal;
            }
        }
    }

    &:hover {
        .entry-thumbnail {
            a {
                img {
                    opacity: .7;
                }
            }
        }
    }
}

/*================================================
Blog Details Area CSS
=================================================*/
.blog-details-area {
    position: relative;
    z-index: 1;

    .row {
        margin: {
            left: -25px;
            right: -25px;
        }

        .col-lg-8,
        .col-lg-4 {
            padding: {
                left: 25px;
                right: 25px;
            }
        }
    }
}

.blog-details {
    .article-content {
        margin-top: 30px;

        .entry-meta {
            margin-bottom: 17px;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    position: relative;
                    display: inline-block;
                    color: var(--blackColor);
                    margin-right: 21px;

                    img {
                        width: 30px !important;
                        height: 30px;
                        border-radius: 50%;
                        padding-right: 3px;
                        position: relative;
                        top: -1.5px;
                    }

                    a {
                        display: inline-block;
                        color: var(--paragraphColor);

                        &:hover {
                            color: var(--mainColor);
                        }
                    }

                    i {
                        color: var(--mainColor);
                        margin-right: 2px;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        top: 11px;
                        right: -14px;
                        width: 6px;
                        height: 1px;
                        background: #bbaeae;
                    }

                    &:last-child {
                        margin-right: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        h3 {
            margin-bottom: 15px;

            font: {
                size: 23px;
                weight: 700;
            }
        }

        .wp-block-gallery {
            &.columns-3 {
                padding-left: 0;
                list-style-type: none;
                display: -ms-flexbox;
                display: flex;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;

                margin: {
                    right: -15px;
                    left: -15px;
                    bottom: 30px;
                    top: 30px;
                }

                li {
                    -ms-flex: 0 0 33.3333%;
                    flex: 0 0 33.3333%;
                    max-width: 33.3333%;

                    padding: {
                        right: 15px;
                        left: 15px;
                    }

                    figure {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .article-features-list {
            padding-left: 0;
            list-style-type: none;

            margin: {
                bottom: 15px;
                left: 20px;
            }

            li {
                margin-bottom: 12px;
                color: var(--paragraphColor);
                position: relative;
                padding-left: 15px;

                &::before {
                    background: var(--mainColor);
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    border-radius: 50%;
                    position: absolute;
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .article-footer {
        display: -ms-flexbox;
        display: -webkit-box;
        display: flex;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;

        padding: {
            top: 15px;
            bottom: 15px;
        }

        border: {
            top: 1px solid #eeeeee;
            bottom: 1px solid #eeeeee;
        }

        margin: {
            top: 30px;
        }

        .article-tags {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;

            span {
                display: inline-block;
                color: var(--blackColor);
                font-size: 20px;
                margin-right: 5px;
                position: relative;
                top: 2px;
            }

            a {
                display: inline-block;
                color: #736262;

                &:hover {
                    color: var(--mainColor);
                }
            }
        }

        .article-share {
            -ms-flex: 0 0 50%;
            -webkit-box-flex: 0;
            flex: 0 0 50%;
            max-width: 50%;

            .social {
                padding-left: 0;
                list-style-type: none;
                text-align: right;

                margin: {
                    bottom: 0;
                }

                li {
                    display: inline-block;
                    margin-left: 5px;

                    a {
                        position: relative;
                        color: var(--blackColor);
                        background-color: #f7f7f7;
                        width: 33px;
                        height: 33px;
                        line-height: 33px;
                        text-align: center;
                        border-radius: 50%;
                        font-size: 13px;
                        z-index: 1;

                        &::before {
                            content: '';
                            position: absolute;
                            top: 0;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            border-radius: 50%;
                            z-index: -1;
                            transform: scale(.8);
                            background: var(--gradientColor);
                            opacity: 0;
                            visibility: hidden;
                            transition: var(--transition);
                        }

                        &:hover {
                            color: var(--whiteColor);

                            &::before {
                                opacity: 1;
                                visibility: visible;
                                transform: scale(1);
                            }
                        }
                    }
                }
            }
        }
    }
}

blockquote,
.blockquote {
    overflow: hidden;
    background-color: #fafafa;
    padding: 50px !important;
    position: relative;
    text-align: center;
    z-index: 1;
    font-family: var(--optionalFontFamily);

    margin: {
        bottom: 20px;
        top: 20px;
    }

    p {
        color: var(--blackColor);
        line-height: 1.6;
        margin-bottom: 0;

        font: {
            style: italic;
            weight: 600;
            size: 20px;
        }
    }

    cite {
        display: none;
    }

    &::before {
        color: #efefef;
        content: "\f10d";
        position: absolute;
        left: 50px;
        top: -60px;
        z-index: -1;

        font: {
            family: "Font Awesome 5 Free";
            size: 140px;
            weight: 900;
        }
    }
}

.comments-area {
    background-color: #f8f8f8;
    padding: 25px 20px;
    margin-top: 30px;

    .comments-title {
        position: relative;
        line-height: initial;

        margin: {
            bottom: 30px;
        }

        font: {
            size: 23px;
            weight: 700;
        }

        &::before {
            content: '';
            height: 25px;
            width: 3px;
            left: -20px;
            position: absolute;
            background: var(--gradientColor);
            top: 50%;
            transform: translateY(-50%);
        }
    }

    ol,
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
    }

    .comment-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
    }

    .children {
        margin-left: 20px;
    }

    .comment-body {
        border-bottom: 1px solid #eeeeee;
        padding-left: 65px;
        color: var(--blackColor);
        font-size: 14px;
        margin-bottom: 20px;
        padding-bottom: 20px;

        .reply {
            margin-top: 15px;

            a {
                border: 1px solid #eeeeee;
                color: var(--paragraphColor);
                display: inline-block;
                padding: 5px 20px;
                border-radius: 30px;
                text-transform: uppercase;

                font: {
                    size: 12px;
                    weight: 700;
                    family: var(--optionalFontFamily);
                }

                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                    border-color: var(--mainColor);
                }
            }
        }
    }

    .comment-meta {
        margin-bottom: .8em;
    }

    .comment-author {
        font-size: 15px;
        margin-bottom: 0.4em;
        position: relative;
        z-index: 2;

        .avatar {
            height: 50px;
            left: -65px;
            position: absolute;
            width: 50px;
        }

        .fn {
            font: {
                weight: 700;
                family: var(--optionalFontFamily);
            }
        }

        .says {
            display: none;
        }
    }

    .comment-metadata {
        color: var(--paragraphColor);
        letter-spacing: 0.08em;
        text-transform: uppercase;

        font: {
            size: 10px;
            weight: 600;
        }

        a {
            color: var(--paragraphColor);

            &:hover {
                color: var(--mainColor);
            }
        }
    }

    .comment-content {
        p {
            font-size: 13px;
        }
    }

    .comment-respond {
        .comment-reply-title {
            margin: 0;
            position: relative;

            font: {
                size: 23px;
                weight: 700;
            }

            &::before {
                content: '';
                height: 25px;
                width: 3px;
                left: -20px;
                position: absolute;
                background: var(--gradientColor);
                top: 50%;
                transform: translateY(-50%);
            }

            #cancel-comment-reply-link {
                font-size: 15px;
                display: inline-block;
            }
        }

        .comment-form {
            overflow: hidden;
        }

        .comment-notes {
            font-size: 13px;

            margin: {
                bottom: 0;
                top: 10px;
            }

            .required {
                color: red;
            }
        }

        .comment-form-comment {
            margin-top: 15px;
            float: left;
            width: 100%;
        }

        label {
            display: block;
            margin-bottom: 5px;
            color: var(--blackColor);

            font: {
                weight: 600;
                family: var(--optionalFontFamily);
            }
        }

        input[type="date"],
        input[type="time"],
        input[type="datetime-local"],
        input[type="week"],
        input[type="month"],
        input[type="text"],
        input[type="email"],
        input[type="url"],
        input[type="password"],
        input[type="search"],
        input[type="tel"],
        input[type="number"],
        textarea {
            display: block;
            width: 100%;
            background-color: var(--whiteColor);
            border: 1px solid #eeeeee;
            padding: 0.625em 0.7375em;
            outline: 0;
            transition: var(--transition);

            &:focus {
                border-color: var(--mainColor);
            }
        }

        .comment-form-author {
            float: left;
            width: 50%;
            padding-right: 10px;
            margin-bottom: 20px;
        }

        .comment-form-email {
            float: left;
            width: 50%;
            padding-left: 12px;
            margin-bottom: 20px;
        }

        .comment-form-url {
            float: left;
            width: 100%;
            margin-bottom: 20px;
        }

        .comment-form-cookies-consent {
            width: 100%;
            float: left;
            position: relative;
            padding-left: 20px;
            margin-bottom: 20px;

            input {
                position: absolute;
                left: 0;
                top: 6px;
            }

            label {
                display: inline-block;
                margin: 0;
                color: var(--paragraphColor);
                font-weight: normal;
            }
        }

        .form-submit {
            float: left;
            width: 100%;

            input {
                background-color: var(--mainColor);
                border: none;
                color: var(--whiteColor);
                padding: 10.5px 25px;
                display: inline-block;
                cursor: pointer;
                outline: 0;
                border-radius: 0;
                text-transform: uppercase;
                transition: var(--transition);

                font: {
                    family: var(--optionalFontFamily);
                    weight: 700;
                    size: 14px;
                }

                &:hover,
                &:focus {
                    color: var(--whiteColor);
                    background-color: var(--optionalColor);
                }
            }
        }
    }
}

/*================================================
Page Title Area CSS
=================================================*/
.page-title-area {
    position: relative;
    z-index: 1;
    background: transparent url(assets/img/page-title-bg.jpg) right top no-repeat;
    text-align: center;

    padding: {
        top: 190px;
        bottom: 100px;
    }
}

.page-title-content {
    h2 {
        margin-bottom: 0;
        color: #094a70;
        font: {
            size: 40px;
            weight: 700;
        }
    }

    ul {
        padding-left: 0;
        list-style-type: none;
        font-family: var(--optionalFontFamily);

        margin: {
            top: 15px;
            bottom: 0;
        }

        li {
            display: inline-block;

            margin: {
                right: 10px;
                left: 10px;
            }

            position: relative;

            font: {
                weight: 600;
                size: 15px;
            }

            a {
                color: var(--mainColor);

                &:hover {
                    color: var(--mainColor);
                }
            }

            &::before {
                content: "\f054";
                position: absolute;
                right: -15px;
                top: 6px;

                font: {
                    weight: 900;
                    family: "Font Awesome 5 Free";
                    size: 10px;
                }
            }

            &:last-child {
                &::before {
                    display: none;
                }
            }
        }
    }
}

.dot-shape1 {
    position: absolute;
    left: 10%;
    bottom: 0;
    z-index: -1;
    animation: animationFramesOne 40s infinite linear;
}

.dot-shape2 {
    position: absolute;
    right: 10%;
    bottom: 0;
    z-index: -1;
    animation: animationFramesOne 20s infinite linear;
}

.dot-shape3 {
    position: absolute;
    right: 20%;
    top: 20%;
    z-index: -1;
    animation: animationFramesOne 20s infinite linear;
}

.dot-shape4 {
    position: absolute;
    left: 20%;
    top: 20%;
    z-index: -1;
    animation: animationFramesOne 20s infinite linear;
}

.dot-shape5 {
    position: absolute;
    left: 20%;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    animation: rotate3d 4s linear infinite;
}

.dot-shape6 {
    position: absolute;
    right: 20%;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    animation: movescale 3s linear infinite;
}

.dot-shape7 {
    position: absolute;
    left: 50%;
    bottom: 5%;
    z-index: -1;
    animation: animationFramesOne 50s infinite linear;
}

.shape16 {
    position: absolute;
    z-index: -1;
    bottom: 30%;
    left: 7%;
    animation-name: rotateme;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.shape17 {
    position: absolute;
    z-index: -1;
    bottom: 30%;
    right: 7%;
    animation-name: rotateme;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

.shape18 {
    position: absolute;
    z-index: -1;
    bottom: 30%;
    right: 35%;
    animation: rotate3d 10s linear infinite;
    opacity: .1;
}

.shape19 {
    position: absolute;
    z-index: -1;
    animation: movebounce 5s linear infinite;
    opacity: .11;
    left: 60%;
    bottom: -15%;
}

.shape20 {
    position: absolute;
    z-index: -1;
    bottom: 45%;
    left: 35%;
    animation-name: rotateme;
    animation-duration: 10s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    opacity: .5;
}

/*================================================
Pagination Area CSS
=================================================*/
.pagination-area {
    margin-top: 40px;
    text-align: center;
    font-family: var(--optionalFontFamily);

    .page-numbers {
        width: 40px;
        height: 40px;
        margin: 0 3px;
        display: inline-block;
        background-color: var(--whiteColor);
        line-height: 40px;
        color: var(--blackColor);
        box-shadow: 0 2px 10px 0 #d8dde6;

        font: {
            size: 16px;
            weight: 700;
        }

        &.current,
        &:hover,
        &:focus {
            background: var(--mainColor);
            color: var(--whiteColor);
            box-shadow: unset;
        }
    }
}

/*================================================
Sidebar Area CSS
=================================================*/
.widget-area {
    .widget {
        margin-top: 30px;

        &:first-child {
            margin-top: 0;
        }

        .widget-title {
            border-bottom: 1px solid #eeeeee;
            padding-bottom: 10px;
            margin-bottom: 20px;
            text-transform: capitalize;
            position: relative;

            font: {
                weight: 700;
                size: 20px;
            }

            &::before {
                content: '';
                position: absolute;
                background: var(--gradientColor);
                bottom: -1px;
                left: 0;
                width: 50px;
                height: 1px;
            }
        }
    }

    .widget_search {
        box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
        background-color: var(--whiteColor);
        padding: 15px;

        form {
            position: relative;

            label {
                display: block;
                margin-bottom: 0;
            }

            .screen-reader-text {
                display: none;
            }

            .search-field {
                background-color: transparent;
                height: 50px;
                padding: 6px 15px;
                border: 1px solid #eeeeee;
                width: 100%;
                display: block;
                outline: 0;
                transition: var(--transition);

                &:focus {
                    border-color: var(--mainColor);
                }
            }

            button {
                position: absolute;
                right: 0;
                outline: 0;
                bottom: 0;
                height: 50px;
                width: 50px;
                z-index: 1;
                border: none;
                color: var(--whiteColor);
                background-color: transparent;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    background: var(--gradientColor);
                    top: 0;
                    transition: var(--transition);
                }

                &::after {
                    content: '';
                    position: absolute;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: -1;
                    background: var(--optionalGradientColor);
                    top: 0;
                    transition: var(--transition);
                    opacity: 0;
                    visibility: hidden;
                }

                &:hover {
                    &::before {
                        opacity: 0;
                        visibility: hidden;
                    }

                    &::after {
                        opacity: 1;
                        visibility: visible;
                    }
                }
            }
        }
    }

    .widget_crimo_posts_thumb {
        position: relative;
        overflow: hidden;

        .item {
            overflow: hidden;
            margin-bottom: 15px;

            &:last-child {
                margin-bottom: 0;
            }

            .thumb {
                float: left;
                height: 80px;
                overflow: hidden;
                position: relative;
                width: 80px;
                margin-right: 15px;

                .fullimage {
                    width: 80px;
                    height: 80px;
                    display: inline-block;

                    background: {
                        size: cover !important;
                        repeat: no-repeat;
                        position: center center !important;
                    }

                    &.bg1 {
                        background-image: url(assets/img/blog/blog1.jpg);
                    }

                    &.bg2 {
                        background-image: url(assets/img/blog/blog2.jpg);
                    }

                    &.bg3 {
                        background-image: url(assets/img/blog/blog3.jpg);
                    }
                }
            }

            .info {
                overflow: hidden;

                time {
                    display: block;
                    color: var(--paragraphColor);
                    text-transform: uppercase;

                    margin: {
                        top: 5px;
                        bottom: 3px;
                    }

                    font: {
                        size: 11px;
                    }
                }

                .title {
                    margin-bottom: 0;
                    line-height: 1.4;

                    font: {
                        size: 16px;
                        weight: 700;
                    }
                }
            }
        }
    }

    .widget_recent_entries {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: var(--paragraphColor);
                padding-left: 14px;
                line-height: 1.5;

                font: {
                    weight: 600;
                    size: 14.5px;
                    family: var(--optionalFontFamily);
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    background: var(--mainColor);
                    position: absolute;
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 7px;
                }

                a {
                    color: var(--paragraphColor);

                    &:hover {
                        color: var(--mainColor);
                    }
                }

                .post-date {
                    display: block;
                    font-size: 13px;
                    color: var(--paragraphColor);
                    margin-top: 4px;
                }
            }
        }
    }

    .widget_recent_comments {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: var(--blackColor);
                padding-left: 14px;
                line-height: 1.5;

                font: {
                    size: 14.5px;
                    weight: 600;
                    family: var(--optionalFontFamily);
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    background: var(--mainColor);
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 7px;
                    position: absolute;
                }

                span {
                    display: inline-block;
                }

                a {
                    color: var(--paragraphColor);
                    display: inline-block;

                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }

    .widget_archive {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                padding-left: 14px;
                color: var(--paragraphColor);

                font: {
                    size: 14.5px;
                    weight: 600;
                    family: var(--optionalFontFamily);
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    background: var(--mainColor);
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 7px;
                    position: absolute;
                }

                a {
                    color: var(--paragraphColor);

                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }

    .widget_categories {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: var(--paragraphColor);
                padding-left: 14px;

                font: {
                    size: 14.5px;
                    family: var(--optionalFontFamily);
                    weight: 600;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    background: var(--mainColor);
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 7px;
                    position: absolute;
                }

                a {
                    color: var(--paragraphColor);

                    &:hover {
                        color: var(--mainColor);
                    }
                }

                .post-count {
                    float: right;
                }
            }
        }
    }

    .widget_meta {
        ul {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;

            li {
                position: relative;
                margin-bottom: 12px;
                color: var(--paragraphColor);
                padding-left: 14px;

                font: {
                    size: 14.5px;
                    family: var(--optionalFontFamily);
                    weight: 600;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                &::before {
                    background: var(--mainColor);
                    height: 7px;
                    width: 7px;
                    content: '';
                    left: 0;
                    top: 7px;
                    position: absolute;
                }

                a {
                    color: var(--paragraphColor);

                    &:hover {
                        color: var(--mainColor);
                    }
                }
            }
        }
    }

    .widget_tag_cloud {
        .widget-title {
            margin-bottom: 12px;
        }
    }

    .tagcloud {
        a {
            display: inline-block;
            color: var(--paragraphColor);

            font: {
                weight: 600;
                size: 12.5px !important;
            }

            padding: 6px 13px;
            border: 1px dashed #eeeeee;
            position: relative;

            margin: {
                top: 8px;
                right: 4px;
            }

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: var(--gradientColor);
                z-index: -1;
                opacity: 0;
                visibility: hidden;
                transition: var(--transition);
                transform: scale(.8);
            }

            &:hover,
            &:focus {
                color: var(--whiteColor);
                border-color: var(--mainColor);

                &::before {
                    opacity: 1;
                    visibility: visible;
                    transform: scale(1);
                }
            }
        }
    }
}

.tab {
    .tabs_item {
        display: none;

        &:first-child {
            display: block;
        }
    }
}

/*================================================
FAQ Area CSS
=================================================*/
.faq-content {
    .section-title {
        margin-bottom: 20px;

        .content {
            -ms-flex: 0 0 100%;
            -webkit-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.faq-accordion {
    .accordion {
        list-style-type: none;
        padding-left: 0;

        margin: {
            bottom: 0;
        }

        .accordion-item {
            display: block;
            box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
            background: var(--whiteColor);
            margin-bottom: 10px;
            border: 0;

            &:last-child {
                margin-bottom: 0;
            }
        }

        .accordion-title {
            padding: 12px 20px 12px 51px;
            color: var(--blackColor);
            position: relative;
            border-bottom: 1px solid transparent;
            margin-bottom: -1px;

            font: {
                size: 15px;
                weight: 700;
                family: var(--optionalFontFamily);
            }

            i {
                position: absolute;
                left: 0;
                top: 0;
                width: 40px;
                text-align: center;
                height: 100%;
                background: var(--mainColor);
                color: var(--whiteColor);

                &::before {
                    position: absolute;
                    left: 0;
                    top: 50%;
                    right: 0;
                    transform: translateY(-50%);
                    font-size: 13px;
                }
            }

            &.active {
                border-bottom-color: #eeeeee;

                i {
                    &::before {
                        content: "\f068";
                    }
                }
            }
        }

        .accordion-content {
            display: none;
            position: relative;
            padding: 15px 15px;

            &.show {
                display: block;
            }
        }
    }
}

.faq-contact {
    margin-top: 110px;

    .section-title {
        text-align: center;

        .content {
            -ms-flex: 0 0 100%;
            -webkit-box-flex: 0;
            flex: 0 0 100%;
            max-width: 100%;
        }
    }
}

.faq-contact-form {
    max-width: 750px;
    margin: 0 auto;
    text-align: center;

    form {
        .form-control {
            background-color: var(--whiteColor);
            border: none;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2);
            height: 50px;

            font: {
                family: var(--optionalFontFamily);
                size: 16px;
            }
        }

        .row {
            margin: {
                left: -7px;
                right: -7px;
            }

            .col-lg-12,
            .col-lg-6 {
                padding: {
                    left: 7px;
                    right: 7px;
                }
            }
        }

        textarea.form-control {
            height: auto;
            padding-top: 15px;
        }

        .btn {
            padding: 13px 35px 15px;
            font-size: 17px;
            margin-top: 8px;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);

            &.disabled {
                cursor: not-allowed !important;

                &:hover,
                &:focus {
                    &::before {
                        opacity: 1;
                        visibility: visible;
                    }

                    &::after {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
    }
}

/*================================================
Contact Area CSS
=================================================*/
.contact-area {
    position: relative;
    z-index: 1;
}

.contact-form {
    form {
        .form-control {
            background-color: var(--whiteColor);
            border: none;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2);
            height: 50px;

            font: {
                family: var(--optionalFontFamily);
                size: 16px;
            }
        }

        .row {
            margin: {
                left: -7px;
                right: -7px;
            }

            .col-lg-12,
            .col-lg-6 {
                padding: {
                    left: 7px;
                    right: 7px;
                }
            }
        }

        textarea.form-control {
            height: auto;
            padding-top: 15px;
        }

        .btn {
            padding: 13px 35px 15px;
            font-size: 17px;
            margin-top: 8px;
            box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);

            &.disabled {
                cursor: not-allowed !important;

                &:hover,
                &:focus {
                    &::before {
                        opacity: 1;
                        visibility: visible;
                    }

                    &::after {
                        opacity: 0;
                        visibility: hidden;
                    }
                }
            }
        }
    }
}

.contact-info {
    padding-right: 25px;

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            position: relative;
            padding-left: 95px;
            color: var(--paragraphColor);
            margin-bottom: 35px;

            .icon {
                border: 1px dashed var(--mainColor);
                width: 75px;
                height: 75px;
                line-height: 75px;
                color: var(--whiteColor);
                border-radius: 50%;
                font-size: 25px;
                text-align: center;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                z-index: 1;

                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    background: var(--gradientColor);
                    border-radius: 50%;
                    margin: 6px;
                    box-shadow: 0 1px 5px 1px var(--mainColor);
                    z-index: -1;
                }
            }

            span {
                display: block;
                margin-bottom: 3px;
                color: var(--blackColor);

                font: {
                    size: 20px;
                    weight: 700;
                    family: var(--optionalFontFamily);
                }
            }

            a {
                color: var(--paragraphColor);

                &:hover {
                    color: var(--mainColor);
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.bg-map {
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
    text-align: center;
    margin: 0 auto;
    opacity: .5;
}

#map {
    height: 500px;
    width: 100%;
}

/*================================================
404 Error Area CSS
=================================================*/
.error-area {
    padding: {
        top: 170px;
        bottom: 100px;
    }

    ;
}

.error-content {
    text-align: center;
    margin: 0 auto;
    max-width: 700px;

    h3 {
        font: {
            size: 40px;
            weight: 700;
        }

        margin: {
            top: 30px;
            bottom: 17px;
        }
    }

    p {
        max-width: 520px;
        margin: 0 auto 20px;
    }
}

/*================================================
Coming Soon Area CSS
=================================================*/
.coming-soon-area {
    position: relative;
    z-index: 1;

    .coming-soon-content {
        height: 100vh;
        position: relative;

        padding: {
            left: 100px;
            right: 100px;
        }

        .logo {
            position: absolute;
            left: 100px;
            top: 30px;
        }

        h3 {
            line-height: 1.3;

            margin: {
                bottom: 25px;
            }

            font: {
                size: 40px;
                weight: 700;
            }
        }

        form {
            max-width: 520px;

            .form-control {
                height: 50px;
                border: none;
                box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2);
            }

            .btn {
                display: block;
                width: 100%;
                padding: 15px 28px;
                box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, .2);
            }

            p {
                margin: {
                    bottom: 0;
                    top: 15px;
                }
            }
        }

        .social {
            position: absolute;
            left: 100px;
            bottom: 30px;
            text-align: center;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                    margin: 0 2px;

                    a {
                        width: 35px;
                        height: 35px;
                        line-height: 37px;
                        border-radius: 50%;
                        background-color: #dddddd;
                        color: var(--blackColor);
                        font-size: 14px;

                        &.twitter {
                            background-color: #1da1f2;
                            color: var(--whiteColor);
                        }

                        &.youtube {
                            background-color: #ff0000;
                            color: var(--whiteColor);
                        }

                        &.facebook {
                            background-color: #3b5998;
                            color: var(--whiteColor);
                        }

                        &.linkedin {
                            background-color: #0077b5;
                            color: var(--whiteColor);
                        }

                        &.instagram {
                            background-color: #c13584;
                            color: var(--whiteColor);
                        }

                        &:hover {
                            background-color: var(--mainColor);
                            color: var(--whiteColor);
                        }
                    }
                }
            }
        }
    }

    .coming-soon-time {
        width: 100%;
        height: 100%;
        position: relative;
        text-align: center;
        z-index: 1;

        background: {
            image: url(assets/img/coming-soon-bg.jpg);
            position: center center;
            size: cover;
            repeat: no-repeat;
        }

        img {
            display: none;
        }

        #timer {
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            z-index: 2;
            max-width: 420px;

            margin: {
                left: auto;
                right: auto;
            }

            div {
                width: 155px;
                height: 155px;
                border: 1px solid rgba(255, 255, 255, 0.3);
                border-radius: 50%;
                display: inline-block;
                color: var(--whiteColor);
                line-height: initial;
                padding-top: 37px;

                margin: {
                    right: 15px;
                    left: 15px;
                    bottom: 15px;
                    top: 15px;
                }

                font: {
                    size: 45px;
                    weight: 700;
                    family: var(--optionalFontFamily);
                }

                span {
                    text-transform: uppercase;
                    display: block;
                    margin-top: 3px;

                    font: {
                        size: 14px;
                        weight: 600;
                    }
                }
            }
        }

        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            background: #000000;
            opacity: .7;
            z-index: -1;
        }
    }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
    background: var(--gradientColor);
    text-align: center;
    border-radius: 5px;
    position: relative;
    z-index: 1;

    padding: {
        top: 60px;
        bottom: 60px;
        left: 90px;
        right: 90px;
    }

    margin: {
        bottom: 80px;
        top: -195px;
    }

    h3 {
        color: var(--whiteColor);
        margin-bottom: 30px;

        font: {
            size: 26px;
            weight: 700;
        }
    }
}

.newsletter-form {
    position: relative;

    .form-control {
        border: none;
        background-color: #37951f;
        border-radius: 5px;
        color: var(--whiteColor);
        height: 50px;

        &::placeholder {
            color: #efefef;
        }
    }

    .row {
        margin: {
            left: -10px;
            right: -10px;
        }

        .col-lg-2,
        .col-lg-3,
        .col-lg-4,
        .col-lg-5,
        .col-lg-12 {
            padding: {
                left: 10px;
                right: 10px;
            }
        }
    }

    button {
        border: none;
        position: relative;
        height: 50px;
        display: block;
        width: 100%;
        z-index: 1;
        color: var(--whiteColor);
        text-transform: uppercase;
        border-radius: 5px;
        transition: var(--transition);
        outline: 0;

        font: {
            size: 14px;
            weight: 700;
        }

        i {
            margin-left: 5px;
        }

        &::before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: var(--optionalGradientColor);
            transition: var(--transition);
            left: 0;
            border-radius: 5px;
            top: 0;
        }

        &::after {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            background: var(--blackColor);
            transition: var(--transition);
            left: 0;
            border-radius: 5px;
            top: 0;
            opacity: 0;
            visibility: hidden;
        }

        &:hover {
            &::after {
                opacity: 1;
                visibility: visible;
            }

            &::before {
                opacity: 0;
                visibility: hidden;
            }
        }
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    position: relative;
    z-index: 1;
    padding-top: 100px;
    background-color: #182c51;
}

.single-footer-widget {
    margin-bottom: 30px;

    .logo {
        margin-bottom: 20px;

        p {
            color: var(--whiteColor);

            margin: {
                top: 18px;
                bottom: 0;
            }
        }
    }

    p {
        color: var(--whiteColor);
        font-size: 14px;
    }

    .social {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            display: inline-block;
            transition: var(--transition);

            margin: {
                left: 0;
                right: 6px;
            }

            a {
                width: 35px;
                height: 35px;
                background-color: var(--blackColor);
                line-height: 36px;
                border-radius: 50%;
                color: var(--whiteColor);
                text-align: center;

                i {
                    &::before {
                        font-size: 13px;
                    }
                }

                &:hover {
                    background-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }

    h3 {
        margin-bottom: 23px;
        color: var(--whiteColor);
        position: relative;
        padding-bottom: 6px;

        font: {
            size: 23px;
            weight: 700;
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 40px;
            height: 2px;
            background: var(--mainColor);
        }
    }

    .footer-services-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 12px;
            color: var(--whiteColor);
            font-size: 14px;

            a {
                display: inline-block;
                color: var(--whiteColor);

                &:hover {
                    color: var(--mainColor);
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .quick-links-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 12px;
            color: var(--whiteColor);
            font-size: 14px;

            a {
                display: inline-block;
                color: var(--whiteColor);

                &:hover {
                    color: var(--mainColor);
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .footer-contact-list {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 6px;
            color: var(--whiteColor);
            font-size: 14px;
            line-height: 1.7;

            a {
                display: inline-block;
                color: var(--whiteColor);

                &:hover {
                    color: var(--mainColor);
                }
            }

            span {
                font-weight: 700;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.copyright-area {
    background-color: #0d1d3b;
    margin-top: 70px;

    padding: {
        top: 20px;
        bottom: 20px;
    }

    p {
        color: var(--whiteColor);

        a {
            display: inline-block;
            color: var(--whiteColor);
            font-weight: 600;

            &:hover {
                color: var(--mainColor);
            }
        }
    }

    ul {
        padding-left: 0;
        margin-bottom: 0;
        list-style-type: none;
        text-align: right;

        li {
            display: inline-block;
            margin-left: 20px;
            position: relative;
            color: var(--whiteColor);
            font-size: 14px;
            line-height: initial;

            a {
                color: var(--whiteColor);

                &:hover {
                    color: var(--mainColor);
                }
            }

            &::before {
                content: '';
                width: 4px;
                height: 1px;
                background: var(--whiteColor);
                left: -11px;
                top: 50%;
                position: absolute;
                transform: translateY(-50%);
            }

            &:first-child {
                margin-left: 0;

                &::before {
                    display: none;
                }
            }
        }
    }
}

.circle-map {
    position: absolute;
    top: 32%;
    right: 18%;
    z-index: -1;
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
    position: fixed;
    cursor: pointer;
    top: 0;
    right: 15px;
    color: var(--whiteColor);
    background-color: var(--mainColor);
    z-index: 4;
    width: 40px;
    text-align: center;
    height: 42px;
    line-height: 42px;
    opacity: 0;
    visibility: hidden;
    transition: .9s;

    &.active {
        top: 98%;
        transform: translateY(-98%);
        opacity: 1;
        visibility: visible;
    }

    i {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        right: 0;
        margin: 0 auto;
        transition: var(--transition);

        &:last-child {
            opacity: 0;
            visibility: hidden;
            top: 60%;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background: var(--optionalGradientColor);
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);
    }

    &:hover,
    &:focus {
        color: var(--whiteColor);

        &::before {
            opacity: 1;
            visibility: visible;
        }

        i {
            &:first-child {
                opacity: 0;
                top: 0;
                visibility: hidden;
            }

            &:last-child {
                opacity: 1;
                visibility: visible;
                top: 50%;
            }
        }
    }
}

/*================================================
Banner Area CSS
=================================================*/
.banner-area {
    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }

    ;

    padding: {
        top: 250px;
        bottom: 340px;
    }

    ;

    &.bg-1 {
        background-image: url(assets/img/banner-bg.jpg);
    }

    &.bg-2 {
        background-image: url(assets/img/banner-bg2.jpg);
    }
}

.banner-content {
    max-width: 700px;

    h1 {
        margin-bottom: 0;
        color: var(--whiteColor);

        font: {
            size: 80px;
            weight: 700;
        }

        ;
    }

    p {
        font-size: 14.5px;
        color: #e8e8e8;
        max-width: 540px;

        margin: {
            top: 30px;
            bottom: 0;
        }

        ;
    }

    .btn-box {
        margin-top: 35px;

        .btn-light {
            margin-left: 15px;
            border-color: var(--whiteColor);
            color: var(--whiteColor);
        }
    }
}

.home-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            position: absolute;
            right: 7%;
            top: 50%;
            transform: translateY(-50%);

            [class*=owl-] {
                display: block;
                margin: 15px 0;
                width: 40px;
                height: 40px;
                background-color: var(--whiteColor);
                border-radius: 0;
                border: 1px solid #ace5c4;
                box-shadow: 4px 4px #ace5c4;
                transition: var(--transition);
                color: var(--blackColor);
                font-size: 20px;

                &:hover {
                    background-color: var(--mainColor);
                    border-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
}

/*================================================
Boxes Area CSS
=================================================*/
.boxes-area {
    padding-bottom: 80px;
    margin-top: -150px;
    position: relative;
    z-index: 1;
}

.single-boxes-box {
    margin-bottom: 30px;
    background-color: var(--whiteColor);
    border: 1px solid #ace5c4;
    box-shadow: 5px 5px #ace5c4;
    transition: var(--transition);
    position: relative;
    padding: 30px 20px 30px 110px;

    .icon {
        position: absolute;
        display: inline-block;
        width: 70px;
        height: 70px;
        top: 30px;
        left: 20px;
        line-height: 70px;
        border-radius: 50%;
        color: var(--mainColor);
        background-color: #f0f0f0;
        text-align: center;
        transition: var(--transition);

        i {
            &::before {
                font-size: 30px;
            }
        }
    }

    h3 {
        margin-bottom: 12px;

        font: {
            size: 23px;
            weight: 700;
        }

        ;
    }

    &:hover {
        border-color: var(--mainColor);
        box-shadow: 5px 5px var(--mainColor);

        .icon {
            background-color: var(--mainColor);
            color: var(--whiteColor);
        }
    }
}

/*================================================
About Area CSS
=================================================*/
.about-area {
    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        }

        ;

        .row {
            margin: {
                left: 0;
                right: 0;
            }

            ;

            .col-lg-6 {
                padding: {
                    left: 0;
                    right: 0;
                }

                ;
            }

            &::before {
                right: 13.5%;
                width: 45%;
            }
        }
    }
}

.about-content {
    .content {
        max-width: 540px;

        padding: {
            left: 30px;
            right: 30px;
        }

        ;
    }
}

/*================================================
Services Area CSS
=================================================*/
.single-services-item {
    margin-bottom: 30px;
    border: 1px solid #ace5c4;
    box-shadow: 5px 5px #ace5c4;
    transition: var(--transition);
    background-color: var(--whiteColor);
    text-align: center;
    position: relative;

    padding: {
        top: 70px;
        bottom: 50px;
        left: 20px;
        right: 20px;
    }

    ;

    .icon {
        margin-bottom: 40px;
        position: relative;
        z-index: 1;
        transition: var(--transition);
        color: var(--mainColor);

        img {
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            z-index: -1;
        }

        i {
            &::before {
                font-size: 50px;
                line-height: 1;
            }
        }
    }

    h3 {
        margin-bottom: 0;
        transition: var(--transition);

        font: {
            size: 23px;
            weight: 700;
        }

        ;
    }

    .content {
        position: absolute;
        left: 0;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
        z-index: 1;
        padding: 15px;
        opacity: 0;
        visibility: hidden;
        transition: var(--transition);

        h3 {
            opacity: 1 !important;
            visibility: visible !important;
            color: var(--whiteColor);
            margin-bottom: 15px;

            a {
                color: var(--whiteColor);
            }
        }

        p {
            color: var(--whiteColor);
        }

        .read-more-btn {
            color: var(--whiteColor);

            i {
                color: var(--whiteColor);
            }
        }
    }

    &:hover {
        border-color: var(--mainColor);
        background-color: var(--mainColor);

        h3 {
            opacity: 0;
            visibility: visible;
        }

        .icon {
            opacity: 0;
            visibility: visible;
        }

        .content {
            opacity: 1;
            visibility: visible;
        }
    }
}

/*================================================
Why Choose Area CSS
=================================================*/
.why-choose-area {
    &.bg-black-color {
        position: relative;
        z-index: 1;

        .section-title-style-two {
            h2 {
                color: var(--whiteColor);
            }

            .text {
                p {
                    color: #f3f3f3;
                }
            }
        }

        &::before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            z-index: -1;
            top: 0;
            background-color: #111111;
            height: 70%;
        }
    }

    .circle-map {
        top: 0;
        right: 39%;
    }
}

.single-choose-box {
    &.with-box-shadow {
        border-radius: 0;
        box-shadow: 5px 5px #ace5c4;

        img {
            border-radius: 0;
        }

        &:hover {
            box-shadow: 5px 5px var(--mainColor);
        }
    }
}

/*================================================
What We Do Area CSS
=================================================*/
.what-we-do-content {
    margin-bottom: -100px;
    padding-right: 30px;

    .single-funfacts {
        margin-bottom: 100px;
    }
}

.what-we-do-image {
    text-align: center;
    box-shadow: 5px 5px var(--mainColor);
}

/*================================================
Projects Area CSS
=================================================*/
.projects-slides-two {
    .single-projects-box {
        background-color: #fcfffb;

        margin: {
            left: 15px;
            right: 15px;
        }

        ;

        .projects-content {
            box-shadow: unset;

            span {
                color: var(--mainColor);
            }
        }

        &:hover {
            background-color: var(--whiteColor);
            box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
        }
    }

    &.owl-theme {
        .owl-nav {
            margin: {
                top: 15px;
                bottom: 30px;
            }

            ;

            [class*=owl-] {
                margin: 0 7px;
                text-align: center;
                height: 50px;
                line-height: 50px;
                background: #57647c;
                color: var(--whiteColor);
                border-radius: 30px;
                transition: var(--transition);
                position: relative;
                z-index: 1;

                font: {
                    weight: 600;
                    size: 17px;
                    family: var(--optionalFontFamily);
                }

                padding: {
                    left: 88px !important;
                    right: 50px !important;
                }

                i {
                    &::before {
                        font-size: 13px;
                    }
                }

                &:after {
                    content: 'Next';
                    position: absolute;
                    color: var(--whiteColor);
                    top: 50%;
                    transform: translateY(-50%);
                    left: 50px;
                }

                &.owl-prev {
                    color: var(--whiteColor);

                    padding: {
                        left: 50px !important;
                        right: 88px !important;
                    }

                    &::after {
                        content: 'Prev';
                        left: auto;
                        right: 50px;
                    }
                }

                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mainColor);
                }
            }
        }
    }
}

/*================================================
Feedback Area CSS
=================================================*/
.feedback-area {
    .section-title-style-two {
        margin-bottom: 45px;

        .content {
            text-align: right;
            padding-right: 30px;
            position: relative;

            &::before {
                content: '';
                position: absolute;
                right: -15px;
                top: 0;
                height: 100%;
                width: 1px;
                background-color: #ffe3d8;
            }
        }

        .text {
            text-align: left;
            padding-left: 50px;

            i {
                font-size: 90px;
                color: var(--mainColor);
                line-height: 1;
            }
        }
    }
}

.single-feedback-box {
    text-align: center;
    margin-bottom: 30px;

    P {
        color: #333333;
        margin-bottom: 0;
        font-size: 22px;
    }

    .client-info {
        margin-top: 25px;

        h3 {
            margin-bottom: 9px;

            font: {
                size: 20px;
                weight: 700;
            }

            ;
        }

        span {
            display: block;
            color: var(--mainColor);
        }
    }
}

.feedback-slides {
    &.owl-theme {
        max-width: 800px;

        margin: {
            left: auto;
            right: auto;
        }

        ;

        .owl-dots {
            margin-top: -10px !important;

            .owl-dot {
                span {
                    width: 13px;
                    height: 13px;
                    margin: 0 3px;
                    background: #001133;
                    transition: var(--transition);
                    border-radius: 50%;
                }

                &:hover,
                &.active {
                    span {
                        background-color: var(--mainColor);
                    }
                }
            }
        }
    }
}

/*================================================
Build Area CSS
=================================================*/
.build-area {
    position: relative;
    z-index: 1;

    &::before {
        background-color: #111111;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50%;
        z-index: -1;
    }
}

.build-inner-area {
    box-shadow: 5px 5px var(--mainColor);

    background: {
        image: url(assets/img/build-bg.jpg);
        position: center center;
        size: cover;
        repeat: no-repeat;
    }

    ;

    padding: {
        top: 80px;
        bottom: 80px;
        right: 60px;
        left: 60px;
    }

    ;

    .bulid-content {
        margin-left: auto;
        max-width: 545px;

        h2 {
            margin-bottom: 18px;
            color: var(--whiteColor);

            font: {
                size: 40px;
                weight: 700;
            }
        }

        p {
            color: #f3f3f3;
        }

        .btn {
            margin-top: 10px;
        }
    }
}

/*================================================
Working Process Area CSS
=================================================*/
.working-process-area {
    background-color: #111111;
    padding-bottom: 80px;

    .section-title-style-two {
        h2 {
            color: var(--whiteColor);
        }

        .text {
            p {
                color: #f3f3f3;
            }
        }
    }
}

.single-working-process-box {
    text-align: center;
    margin-bottom: 30px;

    padding: {
        left: 15px;
        right: 15px;
    }

    ;

    .icon {
        width: 160px;
        height: 160px;
        background-color: var(--whiteColor);
        box-shadow: 5px 5px var(--mainColor);
        line-height: 160px;

        margin: {
            left: auto;
            right: auto;
            bottom: 35px;
        }

        ;
    }

    h3 {
        margin-bottom: 15px;
        color: var(--whiteColor);

        font: {
            size: 24px;
            weight: 700;
        }

        a {
            color: var(--whiteColor);

            &:hover {
                color: var(--mainColor);
            }
        }
    }

    p {
        color: #f3f3f3;
    }

    .read-more-btn {
        color: var(--whiteColor);
    }
}

/*================================================
Team Area CSS
=================================================*/
.single-team-member {
    margin-bottom: 30px;
    text-align: center;

    .team-image {
        position: relative;
        border: 1px solid #ace5c4;
        box-shadow: 5px 5px #ace5c4;
        transition: var(--transition);

        .social {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);

            margin: {
                left: auto;
                right: auto;
            }

            li {
                display: inline-block;
                transition: var(--transition);

                margin: {
                    left: 3px;
                    right: 3px;
                }

                a {
                    width: 35px;
                    height: 35px;
                    background-color: #343434;
                    line-height: 35px;
                    border-radius: 50%;
                    color: var(--whiteColor);

                    i {
                        &::before {
                            font-size: 13px;
                        }
                    }

                    &:hover {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }

    .team-content {
        margin-top: 25px;

        h3 {
            margin-bottom: 0;

            font: {
                size: 23px;
                weight: 700;
            }
        }

        span {
            display: block;
            margin-top: 7px;
            color: var(--mainColor);

            font: {
                family: var(--optionalFontFamily);
                size: 16.5px;
            }
        }
    }

    &:hover {
        .team-image {
            border-color: var(--mainColor);
            box-shadow: 5px 5px var(--mainColor);

            .social {
                opacity: 1;
                visibility: visible;
                bottom: 15px;
            }
        }
    }
}

/*================================================
Clinets Area CSS
=================================================*/
.clients-area {
    position: relative;
    z-index: 2;

    &::before {
        background-color: #f7f9fb;
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 50%;
        z-index: -1;
    }
}

.clients-inner-area {
    background-color: #111111;
    border: 1px solid var(--mainColor);
    box-shadow: 5px 5px #27ae60;

    padding: {
        top: 70px;
        bottom: 70px;
        left: 70px;
        right: 70px;
    }

    ;

    .title {
        h2 {
            margin-bottom: 0;
            color: var(--whiteColor);

            font: {
                size: 30px;
                weight: 700;
            }

            ;
        }
    }

    .clients-list {
        margin-bottom: -30px;

        .clients-image {
            margin-bottom: 30px;
            text-align: center;

            a {
                display: inline-block;

                img {
                    transition: var(--transition);
                    opacity: 0.5;
                }

                &:hover {
                    img {
                        opacity: 1;
                    }
                }
            }
        }
    }
}

/*================================================
Blog Area CSS
=================================================*/
.single-blog-post-item {
    margin-bottom: 30px;
    transition: var(--transition);
    background-color: var(--whiteColor);
    box-shadow: 5px 5px #ace5c4;

    .image {
        a {
            display: block;

            img {
                transition: var(--transition);
            }
        }
    }

    .content {
        padding: 25px;
        border: 1px solid var(--mainColor);
        border-top: none;
        transition: var(--transition);

        .entry-meta {
            margin-bottom: 6px;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                    position: relative;
                    margin-right: 12px;
                    color: var(--paragraphColor);

                    font: {
                        family: var(--optionalFontFamily);
                        size: 15px;
                    }

                    &::before {
                        content: '';
                        position: absolute;
                        right: -7px;
                        top: 50%;
                        transform: rotate(14deg) translateY(-50%);
                        background: var(--paragraphColor);
                        width: 1px;
                        height: 13px;
                    }

                    a {
                        display: inline-block;
                        color: var(--paragraphColor);

                        &:hover {
                            color: var(--mainColor);
                        }
                    }

                    &:last-child {
                        margin-right: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        h3 {
            margin-bottom: 0;
            line-height: 1.4;

            font: {
                size: 23px;
                weight: 700;
            }
        }

        p {
            margin: {
                top: 8px;
                bottom: 0;
            }
        }

        .read-more-btn {
            margin-top: 15px;
        }
    }

    &:hover {
        box-shadow: 5px 5px var(--mainColor);

        .image {
            a {
                img {
                    opacity: .7;
                }
            }
        }

        .content {
            border-color: var(--whiteColor);
        }
    }
}

/*================================================
Subscribe Area CSS
=================================================*/
.subscribe-area {
    &.bg-white-color {
        background: var(--whiteColor);
        border: 1px solid var(--mainColor);
        box-shadow: 5px 5px #27ae60;
        border-radius: 0;

        h3 {
            color: var(--blackColor);
        }

        .newsletter-form {
            .form-control {
                border-radius: 30px;
                background-color: #eeeeee;
                color: var(--blackColor);
                padding-left: 18px;

                &::placeholder {
                    color: #999999;
                }
            }

            button {
                border-radius: 30px;

                &::before,
                &::after {
                    border-radius: 30px;
                }
            }
        }
    }
}

.pt-110 {
    padding-top: 110px;
}

.pb-110 {
    padding-bottom: 110px;
}

.pb-80 {
    padding-bottom: 80px;
}

/*================================================
Navbar Area CSS
=================================================*/
.navbar-area-with-position-relative {
    .evolta-nav {
        padding: {
            left: 0;
            right: 0;
        }

        ;

        .container-fluid {
            padding: {
                left: 0;
                right: 0;
            }

            ;
        }

        .navbar {
            .navbar-nav {
                margin-left: auto;

                .nav-item {
                    a {
                        color: var(--blackColor);

                        &:hover,
                        &:focus,
                        &.active {
                            color: var(--mainColor);
                        }
                    }

                    &:hover,
                    &:focus,
                    &.active {
                        a {
                            color: var(--mainColor);
                        }
                    }

                    .dropdown-menu {
                        li {
                            a {

                                color: var(--blackColor);

                                &:hover,
                                &:focus,
                                &.active {
                                    color: var(--mainColor);
                                }
                            }

                            .dropdown-menu {
                                li {
                                    a {
                                        color: var(--blackColor);

                                        &:hover,
                                        &:focus,
                                        &.active {
                                            color: var(--mainColor);
                                        }
                                    }

                                    .dropdown-menu {

                                        li {
                                            a {
                                                color: var(--blackColor);

                                                &:hover,
                                                &:focus,
                                                &.active {
                                                    color: var(--mainColor);
                                                }
                                            }

                                            .dropdown-menu {
                                                li {
                                                    a {
                                                        color: var(--blackColor);

                                                        &:hover,
                                                        &:focus,
                                                        &.active {
                                                            color: var(--mainColor);
                                                        }
                                                    }

                                                    .dropdown-menu {
                                                        li {
                                                            a {
                                                                color: var(--blackColor);

                                                                &:hover,
                                                                &:focus,
                                                                &.active {
                                                                    color: var(--mainColor);
                                                                }
                                                            }

                                                            .dropdown-menu {
                                                                li {
                                                                    a {
                                                                        color: var(--blackColor);

                                                                        &:hover,
                                                                        &:focus,
                                                                        &.active {
                                                                            color: var(--mainColor);
                                                                        }
                                                                    }

                                                                    .dropdown-menu {
                                                                        li {
                                                                            a {
                                                                                color: var(--blackColor);

                                                                                &:hover,
                                                                                &:focus,
                                                                                &.active {
                                                                                    color: var(--mainColor);
                                                                                }
                                                                            }
                                                                        }
                                                                    }

                                                                    &.active {
                                                                        a {
                                                                            color: var(--mainColor);
                                                                        }
                                                                    }
                                                                }
                                                            }

                                                            &.active {
                                                                a {
                                                                    color: var(--mainColor);
                                                                }
                                                            }
                                                        }
                                                    }

                                                    &.active {
                                                        a {
                                                            color: var(--mainColor);
                                                        }
                                                    }
                                                }
                                            }

                                            &.active {
                                                a {
                                                    color: var(--mainColor);
                                                }
                                            }
                                        }
                                    }

                                    &.active {
                                        a {
                                            color: var(--mainColor);
                                        }
                                    }
                                }
                            }

                            &.active {
                                a {
                                    color: var(--mainColor);
                                }
                            }
                        }
                    }
                }
            }

            .others-options {
                .cart-btn {
                    color: var(--blackColor);

                    &:hover {
                        color: var(--mainColor);
                    }
                }

                .option-item {
                    color: var(--blackColor);
                }

                .btn-primary {
                    &::before {
                        background: var(--mediumPurpleColor);
                    }

                    &::after {
                        background: var(--eggBlueColor);
                    }
                }
            }
        }
    }
}

.navbar-style-wrap {
    position: relative;
}

/*================================================
IT Services Banner Area CSS
=================================================*/
.it-services-banner-area {
    background-image: url(assets/img/it-services-home/it-services-bg.jpg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    position: relative;
    z-index: 1;

    padding: {
        top: 120px;
        bottom: 250px;
    }

    ;

    .container-fluid {
        padding: {
            left: 50px;
            right: 50px;
        }

        ;
    }
}

.it-services-banner-content {
    max-width: 650px;

    h1 {
        font-size: 80px;
        font-weight: bold;
        margin-bottom: 22px;
    }

    p {
        margin-bottom: 0;
    }

    .btn-box {
        margin-top: 30px;

        .btn-primary {
            &::before {
                background: var(--eggBlueColor);
            }

            &::after {
                background: var(--mediumPurpleColor);
            }
        }

        .btn-light {
            margin-left: 15px;
        }
    }
}

/*================================================
Features Style Area CSS
=================================================*/
.features-style-inner-box {
    position: relative;
    z-index: 1;
    margin-top: -170px;
    background-color: var(--whiteColor);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    border-radius: 30px;

    padding: {
        top: 100px;
        bottom: 70px;
        left: 70px;
        right: 70px;
    }

    ;
}

.col-lg-4 {
    &:last-child {
        .features-style-card {
            &::before {
                display: none;
            }
        }
    }
}

.features-style-card {
    max-width: 310px;
    margin: 0 auto 30px;
    position: relative;
    z-index: 1;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        right: -42px;
        width: 1px;
        height: 100%;
        background-color: #DFDFDF;
    }

    .features-image {
        margin-bottom: 25px;
    }

    .features-content {
        h3 {
            font-size: 25px;
            font-weight: 600;
            margin-bottom: 15px;
        }

        p {
            margin-bottom: 15px;
        }

        .features-btn {
            font-size: 18px;
            font-weight: 600;
            color: var(--eggBlueColor);
            font-family: var(--optionalFontFamily);
        }
    }

    &.text-start {
        margin: 0 auto 30px 0;
    }
}

/*================================================
About Wrap Area CSS
=================================================*/
.about-wrap-content {
    padding-left: 50px;

    span {
        display: block;
        color: var(--eggBlueColor);
        text-transform: uppercase;
        margin-bottom: 15px;

        font: {
            weight: 600;
            size: 15px;
            family: var(--optionalFontFamily);
        }
    }

    h3 {
        margin-bottom: 0;

        font: {
            size: 45px;
            weight: 700;
        }
    }

    p {
        margin: {
            bottom: 0;
            top: 15px;
        }
    }

    .wrap-list {
        padding: 0;

        margin: {
            top: 20px;
            bottom: 0;
        }

        ;

        li {
            color: var(--blackColor);
            font-weight: 600;
            list-style-type: none;
            margin-bottom: 20px;
            position: relative;
            padding: 5px 0 5px 38px;

            &:last-child {
                margin-bottom: 0;
            }

            i {
                position: absolute;
                content: "";
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: inline-block;
                height: 30px;
                width: 30px;
                line-height: 30px;
                background-color: var(--mediumPurpleColor);
                color: var(--whiteColor);
                border-radius: 50px;
                text-align: center;
                font-size: 15px;
                transition: var(--transition);
            }

            &:hover {
                i {
                    background-color: var(--eggBlueColor);
                }
            }
        }
    }

    .about-btn {
        margin-top: 30px;

        .btn-primary {
            &::before {
                background: var(--eggBlueColor);
            }

            &::after {
                background: var(--mediumPurpleColor);
            }
        }
    }
}

.about-wrap-image-with-style {
    position: relative;
    z-index: 1;
    border-radius: 15px;

    padding: {
        top: 65px;
        bottom: 35px;
        left: 35px;
        right: 65px;
    }

    ;

    img {
        border-radius: 15px;
    }

    .wrap-shape {
        position: absolute;
        right: 0;
        top: 0;
        z-index: -1;

        img {
            border-radius: 15px;
        }
    }

    .wrap-shape-2 {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;

        img {
            border-radius: 15px;
        }
    }
}

/*================================================
Choose Wrap Area CSS
=================================================*/
.choose-wrap-content {
    padding-right: 50px;

    span {
        display: block;
        color: var(--eggBlueColor);
        text-transform: uppercase;
        margin-bottom: 15px;

        font: {
            weight: 600;
            size: 15px;
            family: var(--optionalFontFamily);
        }
    }

    h3 {
        margin-bottom: 0;

        font: {
            size: 45px;
            weight: 700;
        }
    }

    p {
        margin: {
            bottom: 0;
            top: 15px;
        }
    }

    .wrap-list {
        padding: 0;

        margin: {
            top: 20px;
            bottom: 0;
        }

        ;

        li {
            color: var(--blackColor);
            font-weight: 600;
            list-style-type: none;
            margin-bottom: 20px;
            position: relative;
            padding: 5px 0 5px 38px;

            &:last-child {
                margin-bottom: 0;
            }

            i {
                position: absolute;
                content: "";
                left: 0;
                top: 50%;
                transform: translateY(-50%);
                display: inline-block;
                height: 30px;
                width: 30px;
                line-height: 30px;
                background-color: var(--mediumPurpleColor);
                color: var(--whiteColor);
                border-radius: 50px;
                text-align: center;
                font-size: 15px;
                transition: var(--transition);
            }

            &:hover {
                i {
                    background-color: var(--eggBlueColor);
                }
            }
        }
    }

    .choose-btn {
        margin-top: 30px;

        .btn-primary {
            &::before {
                background: var(--eggBlueColor);
            }

            &::after {
                background: var(--mediumPurpleColor);
            }
        }
    }
}

/*================================================
Services Wrap Area CSS
=================================================*/
.services-wrap-area {
    background-color: #120F28;
    position: relative;
    z-index: 1;

    .section-title-style-two {
        .content {
            span {
                color: var(--eggBlueColor);
            }

            h2 {
                color: var(--whiteColor);
            }
        }

        .text {
            p {
                color: var(--whiteColor);
            }
        }
    }

    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        height: 150px;
        width: 100%;
        background-color: var(--whiteColor);
        z-index: -1;
    }
}

.single-services-wrap-card {
    margin-bottom: 30px;
    background-color: #1F1A3E;
    padding: 45px;
    border-radius: 20px;
    transition: var(--transition);

    span {
        font-size: 40px;
        font-style: italic;
        font-weight: 600;
        color: var(--eggBlueColor);
        display: inline-block;
        margin-bottom: 15px;
    }

    h3 {
        font-size: 25.8px;
        margin-bottom: 20px;
        font-weight: 600;

        a {
            color: var(--whiteColor);

            &:hover {
                color: var(--eggBlueColor);
            }
        }
    }

    p {
        color: var(--whiteColor);
        margin-bottom: 20px;
    }

    .read-more-btn {
        color: var(--eggBlueColor);

        i {
            margin-left: 5px;
            color: var(--whiteColor);
        }
    }

    &:hover {
        transform: translateY(-5px);
    }
}

.funfacts-style-wrap-inner-box {
    background: linear-gradient(to bottom, #ffeeff, #ffe5f3, #ffdde5, #ffd6d5, #fad0c4);
    box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
    border-radius: 20px;
    margin-top: 70px;

    padding: {
        left: 50px;
        right: 50px;
    }

    ;
}

/*================================================
Testimonials Area CSS
=================================================*/
.testimonials-wrap-area {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.single-testimonials-card {
    position: relative;
    z-index: 1;
    max-width: 850px;
    margin: auto;
    text-align: center;
    padding-top: 90px;

    &::before {
        content: "\f121";
        position: absolute;
        left: 0;
        right: 0;
        top: -12px;
        color: var(--eggBlueColor);

        font: {
            family: Flaticon;
            size: 70px;
            style: normal;
        }
    }

    p {
        margin-bottom: 0;
        font-size: 20px;
    }

    .client-info {
        margin-top: 25px;

        img {
            width: 45px !important;
            height: 45px !important;
            display: inline-block !important;
            border-radius: 50%;
        }

        .title {
            text-align: start;
            margin-left: 15px;

            h3 {
                margin-bottom: 0;

                font: {
                    size: 22px;
                    weight: 600;
                }
            }

            span {
                display: block;
                font-weight: 500;
                margin-top: 10px;
                color: var(--mediumPurpleColor);
            }
        }
    }
}

.testimonials-wrap-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;

            [class*=owl-] {
                color: #e1e1e1;
                font-size: 30px;
                margin: 0 6px;
                background: transparent;
                transition: var(--transition);
                position: absolute;
                left: 10px;
                top: 50%;
                transform: translateY(-50%);

                &.owl-next {
                    right: 10px;
                    left: auto;
                }

                i {
                    &::before {
                        font-size: 30px;
                    }
                }

                &:hover {
                    color: var(--eggBlueColor);
                }
            }
        }
    }
}

.testimonials-wrap-map {
    position: absolute;
    left: 0;
    right: 0;
    top: 55%;
    transform: translateY(-55%);
    z-index: -1;
    margin: auto;
    text-align: center;
    opacity: 50%;
    max-width: 580px;
}

/*================================================
Projects Wrap Area CSS
=================================================*/
.projects-wrap-area {
    position: relative;
    z-index: 1;
    overflow: hidden;

    .container-fluid {
        overflow: hidden;
        left: calc((100% - 1320px)/2);
        position: relative;
    }
}

.projects-wrap-content {
    padding-right: 70px;

    span {
        display: block;
        color: var(--eggBlueColor);
        text-transform: uppercase;
        margin-bottom: 12px;

        font: {
            weight: 600;
            size: 15px;
            family: var(--optionalFontFamily);
        }
    }

    h2 {
        margin-bottom: 15px;

        font: {
            size: 45px;
            weight: 700;
        }
    }

    p {
        margin-bottom: 0;
    }

    .projects-wrap-btn {
        margin-top: 25px;

        .btn-primary {
            &::before {
                background: var(--eggBlueColor);
            }

            &::after {
                background: var(--mediumPurpleColor);
            }
        }
    }
}

.single-projects-card {
    margin-bottom: 30px;
    transition: var(--transition);
    background-color: var(--whiteColor);

    a {
        img {
            transition: var(--transition);
        }
    }

    .projects-content {
        transition: var(--transition);
        box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
        padding: 30px 35px;

        span {
            display: block;
            color: var(--paragraphColor);
            margin-bottom: 12px;

            font: {
                size: 13.8px;
                weight: 600;
            }
        }

        h3 {
            margin-bottom: 0;

            font: {
                size: 25px;
                weight: 700;
            }
        }

        p {
            margin: {
                top: 15px;
                bottom: 0;
            }
        }

        .read-more-btn {
            margin-top: 15px;
            color: var(--eggBlueColor);
        }
    }

    &:hover {
        a {
            img {
                opacity: .75;
            }
        }
    }
}

.projects-wrap-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            position: absolute;
            left: -50px;
            top: 50%;
            transform: translateY(-50%);

            [class*=owl-] {
                display: block;
                margin: 0;
                text-align: center;
                height: 50px;
                line-height: 50px;
                width: 50px;
                background: var(--whiteColor);
                color: var(--mainColor);
                box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
                border-radius: 0;
                transition: var(--transition);
                position: relative;
                z-index: 1;

                font: {
                    weight: 600;
                    size: 18.5px;
                    family: var(--optionalFontFamily);
                }

                i {
                    position: relative;
                    top: -1px;

                    &::before {
                        font-size: 16.5px;
                    }
                }

                &:hover {
                    color: var(--whiteColor);
                    background-color: var(--mediumPurpleColor);
                }
            }
        }
    }
}

/*================================================
Partner Area CSS
=================================================*/
.partner-area {
    &.bg-with-gradient-color {
        background-image: linear-gradient(to bottom, #ffedf8, #ffeff6, #fff2f4, #fff4f5, #fef7f6);

        padding: {
            top: 100px;
            bottom: 60px;
        }

        ;
    }
}

/*================================================
Team Wrap Area CSS
=================================================*/
.team-wrap-area {
    .section-title-style-two {
        .content {
            span {
                color: var(--eggBlueColor);
            }
        }
    }
}

.single-team-card {
    margin-bottom: 30px;
    text-align: center;
    border-radius: 5px 5px 0 0;

    .team-image {
        position: relative;
        overflow: hidden;
        border-radius: 15px;

        img {
            border-radius: 15px;
            transition: var(--transition);
        }

        .social {
            padding-left: 0;
            margin-bottom: 0;
            list-style-type: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            visibility: hidden;
            transition: var(--transition);

            margin: {
                left: auto;
                right: auto;
            }

            li {
                display: inline-block;
                transition: var(--transition);

                margin: {
                    left: 3px;
                    right: 3px;
                }

                a {
                    width: 35px;
                    height: 35px;
                    background-color: var(--eggBlueColor);
                    line-height: 35px;
                    border-radius: 50%;
                    color: var(--whiteColor);

                    i {
                        &::before {
                            font-size: 13px;
                        }
                    }

                    &:hover {
                        background-color: var(--mainColor);
                        color: var(--whiteColor);
                    }
                }
            }
        }
    }

    .team-content {
        margin-top: 25px;

        h3 {
            margin-bottom: 0;

            font: {
                size: 24px;
                weight: 700;
            }
        }

        span {
            display: block;
            margin-top: 10px;
            color: var(--paragraphColor);
            font-size: 14px;
            font-weight: 500;
        }
    }

    &:hover {
        .team-image {
            .social {
                opacity: 1;
                visibility: visible;
                bottom: 15px;
            }

            img {
                transform: scale(1.1);
            }
        }
    }
}

/*================================================
Subscribe Wrap Area CSS
=================================================*/
.subscribe-wrap-area {
    position: relative;
    z-index: 1;

    &::before {
        position: absolute;
        content: "";
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #FFF3F8;
        z-index: -1;
        width: 100%;
        height: 120px;
    }
}

.subscribe-wrap-inner-box {
    background: var(--eggBlueColor);
    text-align: center;
    border-radius: 20px;

    padding: {
        top: 60px;
        bottom: 60px;
        left: 90px;
        right: 90px;
    }

    h3 {
        color: var(--whiteColor);
        margin-bottom: 30px;

        font: {
            size: 27.5px;
            weight: 700;
        }
    }

    .newsletter-form {
        .form-control {
            background-color: #239596;
            color: var(--whiteColor);

            &::placeholder {
                color: var(--whiteColor);
            }
        }

        button {
            &::before {
                background: var(--mediumPurpleColor);
            }

            &::after {
                background: var(--mainColor);
            }
        }
    }
}

/*================================================
Blog Wrap Area CSS
=================================================*/
.blog-wrap-area {
    background-color: #FFF3F8;
    overflow: hidden;

    .section-title-style-two {
        .content {
            span {
                color: var(--eggBlueColor);
            }
        }
    }

}

.single-blog-item {
    position: relative;
    padding: 30px 38px;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    transition: var(--transition);

    &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: var(--whiteColor);
        transition: .4s;
    }

    .entry-post-content {
        position: relative;
        z-index: 1;

        .entry-meta {
            margin-bottom: 15px;

            ul {
                padding-left: 0;
                margin-bottom: 0;
                list-style-type: none;

                li {
                    display: inline-block;
                    position: relative;
                    margin-right: 12px;
                    color: var(--paragraphColor);
                    font-size: 15.5px;
                    font-weight: 500;
                    font-family: var(--optionalFontFamily);
                    transition: .4s;

                    &::before {
                        content: '';
                        position: absolute;
                        right: -7px;
                        top: 50%;
                        transform: rotate(14deg) translateY(-50%);
                        background: var(--paragraphColor);
                        width: 1px;
                        height: 14px;
                        transition: .4s;
                    }

                    a {
                        display: inline-block;
                        color: var(--paragraphColor);
                        transition: .4s;

                        &:hover {
                            color: var(--eggBlueColor);
                        }
                    }

                    &:last-child {
                        margin-right: 0;

                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }

        h3 {
            margin-bottom: 0;
            line-height: 1.5;
            transition: .4s;

            font: {
                size: 25px;
                weight: 700;
            }

            a {
                &:hover {
                    color: var(--eggBlueColor) !important;
                }
            }
        }

        p {
            transition: .4s;

            margin: {
                top: 12px;
                bottom: 0;
            }
        }

        .read-more-btn {
            margin-top: 15px;
            transition: .4s;
            color: var(--blackColor);

            i {
                transition: .4s;
            }
        }
    }

    &.active {
        &::before {
            background: #000000;
            opacity: .70;
        }

        .entry-post-content {
            .entry-meta {
                ul {
                    li {
                        color: var(--eggBlueColor);

                        &::before {
                            background: var(--eggBlueColor);
                        }

                        a {
                            color: var(--eggBlueColor);
                        }
                    }
                }
            }

            h3 {
                a {
                    color: var(--whiteColor);
                }
            }

            p {
                color: var(--whiteColor);
            }

            .read-more-btn {
                color: var(--eggBlueColor);

                i {
                    color: var(--eggBlueColor);
                }
            }
        }
    }

    &:hover {
        &::before {
            background: #000000;
            opacity: .70;
        }

        .entry-post-content {
            .entry-meta {
                ul {
                    li {
                        color: var(--eggBlueColor);

                        &::before {
                            background: var(--eggBlueColor);
                        }

                        a {
                            color: var(--eggBlueColor);
                        }
                    }
                }
            }

            h3 {
                a {
                    color: var(--whiteColor);
                }
            }

            p {
                color: var(--whiteColor);
            }

            .read-more-btn {
                color: var(--eggBlueColor);

                i {
                    color: var(--eggBlueColor);
                }
            }
        }
    }
}

.blog-view-more-btn {
    text-align: center;
    margin-top: 35px;

    .btn-primary {
        &::before {
            background: var(--eggBlueColor);
        }

        &::after {
            background: var(--mediumPurpleColor);
        }
    }
}

/*================================================
Footer Area CSS
=================================================*/
.footer-area {
    &.bg-with-black-color {
        background-color: #120F28;

        .single-footer-widget {
            h3 {
                &::before {
                    background: var(--eggBlueColor);
                }
            }

            .social {
                li {
                    a {
                        background-color: var(--blackColor);
                        color: var(--whiteColor);

                        &:hover {
                            background-color: var(--eggBlueColor);
                            color: var(--whiteColor);
                        }
                    }
                }
            }

            .footer-services-list {
                li {
                    a {
                        color: var(--whiteColor);

                        &:hover {
                            color: var(--eggBlueColor);
                        }
                    }
                }
            }

            .quick-links-list {
                li {
                    a {
                        color: var(--whiteColor);

                        &:hover {
                            color: var(--eggBlueColor);
                        }
                    }
                }
            }

            .footer-contact-list {
                li {
                    a {
                        color: var(--whiteColor);

                        &:hover {
                            color: var(--eggBlueColor);
                        }
                    }
                }
            }
        }
    }
}

/*================================================
IT Solutions Area CSS
=================================================*/
.it-solutions-item {
    background-image: url(assets/img/it-solutions-home/banner-bg-1.jpg);

    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }

    ;

    padding: {
        top: 280px;
        bottom: 200px;
    }

    ;

    &.bg-2 {
        background-image: url(assets/img/it-solutions-home/banner-bg-2.jpg);
    }

    &.bg-3 {
        background-image: url(assets/img/it-solutions-home/banner-bg-3.jpg);
    }

    .container-fluid {
        padding: {
            left: 50px;
            right: 50px;
        }

        ;
    }
}

.it-solutions-banner-content {
    max-width: 650px;

    h1 {
        font-size: 80px;
        font-weight: bold;
        margin-bottom: 22px;
        color: var(--whiteColor);
    }

    p {
        margin-bottom: 0;
        color: var(--whiteColor);
    }

    .btn-box {
        margin-top: 30px;

        .btn-primary {
            &::before {
                background: var(--eggBlueColor);
            }

            &::after {
                background: var(--mediumPurpleColor);
            }
        }

        .btn-light {
            margin-left: 15px;
            color: var(--whiteColor);
        }
    }
}

.it-solutions-slides {
    &.owl-theme {
        .owl-nav {
            margin-top: 0;
            position: absolute;
            right: 4.5%;
            top: 50%;
            transform: translateY(-50%) translateX(-4.5%);

            [class*=owl-] {
                display: block;
                margin: 15px 0;
                width: 40px;
                height: 40px;
                background-color: transparent;
                border-radius: 50px;
                border: 1px solid var(--whiteColor);
                transition: var(--transition);
                color: var(--whiteColor);
                font-size: 20px;

                &:hover {
                    background-color: var(--mainColor);
                    border-color: var(--mainColor);
                    color: var(--whiteColor);
                }
            }
        }
    }
}

/*================================================
Video Wrap Area CSS
=================================================*/
.video-wrap-area {
    overflow: hidden;
    background-image: url(assets/img/it-solutions-home/video-bg.jpg);

    background: {
        position: center center;
        size: cover;
        repeat: no-repeat;
    }

    ;

    padding: {
        top: 250px;
        bottom: 250px;
    }

    ;

    .container-fluid {
        padding: {
            left: 0;
            right: 0;
        }

        ;
    }
}

.video-wrap-content {
    position: relative;

    .video-btn {
        position: absolute;
        text-align: center;
        left: 0;
        right: 0;
        margin: auto;
        display: inline-block;
        top: 50%;
        transform: translateY(-50%);
        width: 90px;
        height: 90px;
        line-height: 90px;
        background-color: transparent;
        border: 1px solid var(--whiteColor);
        border-radius: 50%;
        color: var(--whiteColor);
        z-index: 1;

        i {
            &::before {
                font-size: 38px;
            }
        }

        &::after,
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            bottom: 0;
            left: 0;
            border-radius: 50%;
            border: 1px solid var(--whiteColor);
        }

        &::before {
            animation: ripple 2s linear infinite
        }

        &::after {
            animation: ripple 2s linear 1s infinite
        }

        &:hover,
        &:focus {
            background-color: var(--eggBlueColor);
            color: var(--whiteColor);
            border: 1px solid var(--eggBlueColor);
        }
    }
}


