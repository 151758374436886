/*
Flaticon icon font: Flaticon
*/
@font-face {
    font-family: "Flaticon";
    src: url("../../assets/fonts/Flaticon.eot");
    src: url("../../assets/fonts/Flaticon.eot?#iefix") format("embedded-opentype"),
    url("../../assets/fonts/Flaticon.woff2") format("woff2"),
    url("../../assets/fonts/Flaticon.woff") format("woff"),
    url("../../assets/fonts/Flaticon.ttf") format("truetype"),
    url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
    font-weight: normal;
    font-style: normal;
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
        font-family: "Flaticon";
        src: url("../../assets/fonts/Flaticon.svg#Flaticon") format("svg");
    }
}
[class^="flaticon-"]:before, [class*=" flaticon-"]:before,
[class^="flaticon-"]:after, [class*=" flaticon-"]:after {   
    font-family: Flaticon;
    font-size: 15px;
    font-style: normal;
}

.flaticon-play-button:before { content: "\f100"; }
.flaticon-shopping-basket:before { content: "\f101"; }
.flaticon-search:before { content: "\f102"; }
.flaticon-branch:before { content: "\f103"; }
.flaticon-programming-code:before { content: "\f104"; }
.flaticon-tools-and-utensils:before { content: "\f105"; }
.flaticon-add:before { content: "\f106"; }
.flaticon-add-1:before { content: "\f107"; }
.flaticon-substract:before { content: "\f108"; }
.flaticon-minus:before { content: "\f109"; }
.flaticon-plugin:before { content: "\f10a"; }
.flaticon-computer-graphic:before { content: "\f10b"; }
.flaticon-data:before { content: "\f10c"; }
.flaticon-analysis:before { content: "\f10d"; }
.flaticon-code:before { content: "\f10e"; }
.flaticon-desktop-application:before { content: "\f10f"; }
.flaticon-application:before { content: "\f110"; }
.flaticon-mobile:before { content: "\f111"; }
.flaticon-application-1:before { content: "\f112"; }
.flaticon-cyber-security:before { content: "\f113"; }
.flaticon-security:before { content: "\f114"; }
.flaticon-launch:before { content: "\f115"; }
.flaticon-web:before { content: "\f116"; }
.flaticon-writing:before { content: "\f117"; }
.flaticon-right-arrow:before { content: "\f118"; }
.flaticon-arrow-pointing-to-right:before { content: "\f119"; }
.flaticon-left-arrow:before { content: "\f11a"; }
.flaticon-arrow-pointing-to-left:before { content: "\f11b"; }
.flaticon-facebook-letter-logo:before { content: "\f11c"; }
.flaticon-twitter-black-shape:before { content: "\f11d"; }
.flaticon-instagram-logo:before { content: "\f11e"; }
.flaticon-linkedin-letters:before { content: "\f11f"; }
.flaticon-youtube:before { content: "\f120"; }
.flaticon-top:before { content: "\f121"; }
.flaticon-maps-and-location:before { content: "\f122"; }
.flaticon-link-symbol:before { content: "\f123"; }
.flaticon-right-quotes-symbol:before { content: "\f124"; }
.flaticon-paper-plane:before { content: "\f125"; }
.flaticon-copyright:before { content: "\f126"; }
.flaticon-down-arrow:before { content: "\f127"; }
.flaticon-up-arrow:before { content: "\f128"; }